import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import api from '../../utills/api';
import CategoriesUserCtgItem from '../../components/CategoriesUserCtgItem';
import {Box, CircularProgress, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer} from "@material-ui/core";

function CategoriesUserCtg({categoryId, subCategoryId}) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dropDownOptions, setDropdownOptions] = useState([]);

    useEffect(() => {
        if (subCategoryId) {
            setIsLoading(true);

            api.categories
                .getUserCategories(subCategoryId)
                .then((res) => {
                    if (res.data) {
                        setIsLoading(false);
                        setData(res.data);
                    }
                })
        }
    }, [subCategoryId]);

    useEffect(() => {
        if (categoryId) {
            api.categories
                .getGroups(categoryId)
                .then((res) => {
                    if (res) {
                        setDropdownOptions(res);
                    }
                })
        }
    }, [categoryId]);

    const handleSetGroup = (option, userCategoryId) => {
        const body = {
            "name": option.name,
            "user_category": true,
            "category_id": userCategoryId,
            "parent_category_id": categoryId
        };

        api.categories.addGroup(body);
    }

    return isLoading ? (
        <Paper className="fullWidth">
            <Box display="flex" alignItems="center" justifyContent="center" className="fullHeight">
                <CircularProgress color="secondary" />
            </Box>
        </Paper>
    ) : (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Count</TableCell>
                        <TableCell align="left">User category</TableCell>
                        <TableCell align="left">Assign a group</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!Array.isArray(data) || !data.length ? (
                        <TableRow>
                            <TableCell colSpan={3}>No categories found</TableCell>
                        </TableRow>
                    ) : data.map(userCtg => (
                        <CategoriesUserCtgItem
                            key={userCtg.user_category_id}
                            category={userCtg}
                            dropDownOptions={dropDownOptions}
                            handleSetGroup={handleSetGroup}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

CategoriesUserCtg.propTypes = {
    categoryId: PropTypes.number.isRequired,
    subCategoryId: PropTypes.number.isRequired,
}

export default CategoriesUserCtg;
