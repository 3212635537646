import React, { useEffect, useState } from 'react';
import {
	LinearProgress,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Box,
	Button,
	Link,
	TextField,
	Grid
} from '@material-ui/core';
import moment from 'moment';

import PaginationComponent from '../../components/Pagination/indedx';
import api from '../../utills/api';
import Aside from '../../components/Aside';
import Header from '../../components/Header';
import styles from './style.module.scss';
import Window from "../../components/Modal";
import { ORDER } from '../../enums/orderByEnums';
import { PATH } from '../../utills/Path';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getTotalUserStatistics } from '../../redux/dashboard/actions';
import { roundPercentage } from '../../utills/dashboard';

function Users() {
	const dashboard = useSelector((state) => state.dashboard);
	const history = useHistory();
	const dispatch = useDispatch();
	
	const [isLoading, setIsLoading] = useState(false);
	const [appVersions, setAppVersions] = useState(null);
	const [search, setSearch] = useState(null);
	const [modalData, setModalData] = useState(null);
	const [users, setUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [rows, setRows] = useState([]);

	const [pagination, setPagination] = useState({
		page: 1,
		pageCount: 1,
	});

	const downloadExcel = (id) => {
		console.log(id);
		api.users.getUsersExcel(id).catch((err) => {
			console.log(err);
		}).then(res => {
			const aElement = document.createElement('a');
			aElement.setAttribute('download', 'userList.xlsx');
			const href = URL.createObjectURL(res);
			aElement.href = href;
			aElement.setAttribute('target', '_blank');
			aElement.click();
			URL.revokeObjectURL(href);
		});
	}

	const [sortCriteria, setSortCriteria] = useState({
		feild: null,
		order: null,
	});

	const [stateFilter, setFilter] = useState({
		limit: 50,
		type: null,
		search: null,
		created: null,
		statusUser: null,
		selectedVersion: null,
		verification: null,
		device: null,
		subscription: null,
		nextPayment: null,
		typeTerm: null,
		deviceSubscription: null,
		sortBy: null,
		order: null,
		changed: true,
	});

	const [counters,setCounters] = useState({
		connectionType: {
			saltedge: 0,
			leantech: 0,
			multiple: 0,
		},
		verifications: {
			verified: 0,
			notVerified: 0,
			deleted: 0,
		},
		devices: {
			android: 0,
			ios: 0,
			none: 0,
		},
		appVersions: null,
		subscriptions: {
			new: 0,
			subscribed: 0,
			unSubscribed: 0,
			cancelled: 0,
			trial: 0,
		},
		nextPaymentTypes:{
			monthly: 0,
			yearly: 0,
			none: 0,
		},
		typeTerm:{
			monthly: 0,
			yearly: 0,
			trial: 0,
			none: 0,
		},
		deviceSubscription:{
			iOS: 0,
			android: 0,
			none: 0,
		}
	});

	useEffect(() => {
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		const searchParams = new URLSearchParams(history.location.search);
		const page = searchParams.get("page") || 1;
		const pageCount = Math.ceil(users.length / stateFilter.limit);
		
		setPagination({
			...pagination,
			pageCount,
			page,
		});
		
		filterData();
		countersHandler();
	}, [users]);
	
	useEffect(() => {
		setCounters({
			...counters,
			appVersions: {
				...appVersions?.reduce((versions, version) => {
					versions[version.appVersion] = version.count;
					return versions;
				}, {}),
			}
		})
	}, [appVersions]);

	useEffect(() => {
		paginationHandler(1);
	}, [filteredUsers]);

	useEffect(() => {
		filterData();
	}, [stateFilter]);

	const fetchData = () => {
		setIsLoading(true);
		api.users.getUsers(1).then((res) => {
			console.log(res);
			if (res) {
				setIsLoading(false);
				setUsers(res);
			}
		});
		
		api.users.getAppVersions().then((res) => {
			if (res) {
				console.log(res);
				setAppVersions(res);
			}
		})
		.catch((err) => {
			console.log(err);
		});
		
		dispatch(getTotalUserStatistics());
	}
	
	const renderConnections = ({ connections }) => {
		if (connections.length > 0) {
			return connections.length === 1 ? (
				<>
					<TableCell><Link component="button" onClick={() => history.push({ pathname: PATH.home, search: `?search=${connections[0].providerConnectionId}` })}>{connections[0].providerConnectionId}</Link></TableCell>
					<TableCell>{connections[0].type}</TableCell>
				</>
			) : (
				<>
					<TableCell>
						<Link component="button" onClick={() => setModalData(connections)}>
							{connections.length} connections. View all
						</Link>
					</TableCell>
					<TableCell>MULTIPLE</TableCell>
				</>
			);
		} else {
			return (
				<>
					<TableCell />
					<TableCell />
				</>
			);
		}
	};
	
	const handleFilter = (key, value) => {
		setFilter({
			...stateFilter,
			[key]: value
		});
	};

	const paginationHandler = (page) => {
		setPagination({
			...pagination,
			pageCount: Math.ceil(filteredUsers.length / stateFilter.limit),
			page,
		});

		const searchParams = new URLSearchParams(location.search);
		searchParams.set('page', page);
		history.push({
			search: searchParams.toString(),
		});

		let paginated = [...filteredUsers].slice(
			(page - 1) * stateFilter.limit,
			page * stateFilter.limit
		);

		setRows(paginated);
	};

	const handleSort = (feild) =>{

		if (sortCriteria.order === ORDER.ASC) {
			setSortCriteria({
				...sortCriteria,
				feild,
				order: ORDER.DESC,
			});
		} else {
			setSortCriteria({
				...sortCriteria,
				feild,
				order: ORDER.ASC,
			});
		}

		if(feild === 'created') {
			if (sortCriteria.order === ORDER.ASC) {
				setFilteredUsers([...filteredUsers].sort((a, b) => moment(a[feild]) > moment(b[feild]) ? 1 : -1));
			} else {
				setFilteredUsers([...filteredUsers].sort((a, b) => moment(a[feild]) < moment(b[feild]) ? 1 : -1));
			}
		}else if (feild === 'connection'){
			if (sortCriteria.order === ORDER.ASC) {
				setFilteredUsers([...filteredUsers].sort((a, b) => a.connections.length > b.connections.length ? 1 : -1));
			} else {
				setFilteredUsers([...filteredUsers].sort((a, b) => a.connections.length < b.connections.length ? 1 : -1));
			}
		} else {
			if (sortCriteria.order === ORDER.ASC) {
				setFilteredUsers([...filteredUsers].sort((a, b) => a[feild] > b[feild] ? 1 : -1));
			} else {
				setFilteredUsers([...filteredUsers].sort((a, b) => a[feild] < b[feild] ? 1 : -1));
			}
		}
	}
	
	const handleSearch = () => {
		handleFilter("search", search);
	};

	const handleCloseModal = () => {
		setModalData(null);
	}

	const filterData = () => {
		if(users) {
			
			let filtered = [...users].filter((user) => {
				if (stateFilter.type || stateFilter.statusUser){
					if(user.connections.length === 0 && stateFilter.type) return;

					if(user.connections.length === 1 && (stateFilter.type !== "MULTIPLE" && user.connections[0].type !== stateFilter.type))return;
					
					if(!(user.connections.length > 1) && stateFilter.type === "MULTIPLE") return;

					if(stateFilter.statusUser === "deleted" && !user.deletedAt) return;
					
					if(stateFilter.statusUser === "verified" && !user.is_verified)return;
					
					if(stateFilter.statusUser === "not verified" && (user.is_verified || user.deletedAt))return;
				}

				if(stateFilter.selectedVersion ){
					if(stateFilter.selectedVersion === "no versions"){
						if(user.appVersion) return;
						
					} else if(stateFilter.selectedVersion !== user.appVersion) return;
				} 

				if(stateFilter.device && stateFilter.device !== user.deviceType) return;

				if(stateFilter.subscription && stateFilter.subscription !== user.subscription?.type) return;

				if(stateFilter.nextPayment){
					if(stateFilter.nextPayment === "NONE"){
						if(user.subscription?.next_payment_type_term) return;
					} else if(stateFilter.nextPayment !== user.subscription?.next_payment_type_term) return;
				}

				if(stateFilter.search?.length > 0 && !user.email?.includes(stateFilter.search)) return;
				
				if(stateFilter.typeTerm){
					if(stateFilter.typeTerm === "NONE"){
						if(user.subscription?.type_term) return;
					} else if(stateFilter.typeTerm !== user.subscription?.type_term) return;
				}

				if(stateFilter.deviceSubscription){
					if(stateFilter.deviceSubscription === "NONE"){
						if(user.subscription?.device_type) return;
					} else if(stateFilter.deviceSubscription !== user.subscription?.device_type) return;
				}

				return user;
			});

			setFilteredUsers(filtered);
		}
	}

	const countersHandler = () => {
		const newCounters = {
			connectionType: {
				saltedge: 0,
				leantech: 0,
				multiple: 0,
			},
			verifications: {
				verified: 0,
				notVerified: 0,
				deleted: 0,
			},
			devices: {
				android: 0,
				ios: 0,
				none: 0,
			},
			appVersions: null,
			subscriptions: {
				new: 0,
				subscribed: 0,
				unSubscribed: 0,
				cancelled: 0,
				trial: 0,
			},
			nextPaymentTypes:{
				monthly: 0,
				yearly: 0,
				none: 0,
			},
			typeTerm:{
				monthly: 0,
				yearly: 0,
				trial: 0,
				none: 0,
			},
			deviceSubscription:{
				iOS: 0,
				android: 0,
				none: 0,
			},
		};

		users.forEach(user => {
			const {connections,
					is_verified,
					deletedAt,
					deviceType,
					appVersion,
					subscription
					} = user;
			
			switch (connections.length) {
				case 0:
					break;
			
				case 1:
					if(connections[0].type === "SALTEDGE") newCounters.connectionType.saltedge++;
					if(connections[0].type === "LEANTECH") newCounters.connectionType.leantech++;
					
					break;

				case 2:
					newCounters.connectionType.multiple++;
			}

			switch (is_verified) {
				case true:
					newCounters.verifications.verified++;
					break;
				case false:
					switch (deletedAt) {
						case null:
							newCounters.verifications.notVerified++;
							break;
						
						default:
							newCounters.verifications.deleted++;
							break;
					}

					break;
			}

			switch (deviceType) {
				case "Android":
					newCounters.devices.android++;
					break;

				case "iOS":
					newCounters.devices.ios++;
					break;

				default:
					newCounters.devices.none++;
					break;
			}

			switch (subscription?.device_type) {
				case "ANDROID":
					newCounters.deviceSubscription.android++;
					break;

				case "IOS":
					newCounters.deviceSubscription.ios++;
					break;

				default:
					newCounters.deviceSubscription.none++;
					break;
			}

			if(appVersion){
				if(!newCounters.appVersions) newCounters.appVersions = {};
				if(!newCounters.appVersions[appVersion]) newCounters.appVersions[appVersion] = 0;
				newCounters.appVersions[appVersion]++;
			}

			switch (subscription?.type) {
				case "NEW":
					newCounters.subscriptions.new++;
					break;

				case "SUBSCRIBED":
					newCounters.subscriptions.subscribed++;
					break;

				case "UNSUBSCRIBED":
					newCounters.subscriptions.unSubscribed++;
					break;

				case "CANCELED":
					newCounters.subscriptions.cancelled++;
					break;

				case "TRIAL":
					newCounters.subscriptions.trial++;
					break;

				default:
					break;
			}

			switch (subscription?.next_payment_type_term) {
				case "MONTH":
					newCounters.nextPaymentTypes.monthly++;
					break;

				case "YEAR":
					newCounters.nextPaymentTypes.yearly++;
					break;

				default:
					newCounters.nextPaymentTypes.none++;
					break;
			}

			switch (subscription?.type_term) {
				case "MONTH":
					newCounters.typeTerm.monthly++;
					break;

				case "YEAR":
					newCounters.typeTerm.yearly++;
					break;

				case "TRIAL":
					newCounters.typeTerm.trial++;
					break;

				default:
					newCounters.typeTerm.none++;
					break;
			}

			
			setCounters(newCounters);
		});
	};

	const clearFilters = () => {
		setSearch("");
		setFilter({
			limit: 50,
			type: "",
			search: "",
			created: "",
			statusUser: "",
			selectedVersion: "",
			verification: "",
			device: "",
			subscription: "",
			nextPayment: "",
			sortBy: "",
			order: "",
			changed: "",
		});
	}

	return (
		<Box>
			<Header />
			<section>
				<Aside />
				<main className="content">
					{isLoading && <LinearProgress color="secondary" />}
					<div className="wrapper">
						<h1 className={styles.basicTitle}>Users</h1>
						
						<div className={styles.table}>
							<header className={styles.header}>
								<div className={styles.header_left}>
									<h5 className={styles.title}>Users Statistics</h5>
									<div className={styles.header_left_wrapper}>
										<div className={styles.dayWrapper}>
											<ul>
												<li>
													<h5 className={styles.small_title}>Total Number of Users</h5>
													<h4>{dashboard.userCountAll}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total users with +</h5>
													<h4 >{dashboard.userWithPlus}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Realistic Number of Users</h5>
													<h4>{dashboard.totalRealisticUsers}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Numbers of Users with Bank Connections</h5>
													<h4>{dashboard.userConnectionCountAllUniq}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Users with Bank Connections</h5>
													<h4>{roundPercentage(dashboard.userConnectionCountAllUniq / (dashboard.totalRealisticUsers)) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Realistic users up to last month</h5>
													<h4>{dashboard.userToLastMonth}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Increase/decrease in Number of Users from last month so far</h5>
													<h4>{dashboard.userLastMonth}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage Increase/decrease in Number of Users from last month </h5>
													<h4>{(roundPercentage((dashboard.userLastMonth) / (dashboard.userToLastMonth))) + "%"}</h4>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</header>
							<TableContainer component={Paper} className={styles.wrapper}>
								<Box sx={{ m: 3}} display="flex" justifyContent="start" width={"100%"}>
									<TextField id="search" size="small" className={styles.searchBar}
										label="Search" variant="outlined" value={search} onChange={(e) => setSearch(e.target.value)} />
									<div className={styles.spacing}>
									</div>
									<Button variant="contained" className={styles.spacing} onClick={
										()=>{
											handleSearch();
										}
									}>Find</Button>
									<div className={styles.spacing}>
									</div>
									<Button variant="contained" className={styles.spacing} color={"primary"} onClick={()=>{
										dispatch(fetchData());
									}}>
										Update data
									</Button>
									<div className={styles.spacing}>
									</div>
									<Button variant="contained" className={styles.spacing} color={"primary"} onClick={()=>{
										downloadExcel();
									}}>
										Excel
									</Button>
									<div className={styles.spacing}>
									</div>
									<Button variant="contained" className={styles.spacing} onClick={()=>{
										clearFilters();
									}}>
										Clear Filters
									</Button>
									<div className={styles.spacing}>
									</div>
									<Grid>
										<p>Page Limit:</p>
										<select
											value={stateFilter.limit}
											onChange={(val)=>handleFilter("limit",val.target.value)}
										>
											<option value={10}>10</option>
											<option value={20}>25</option>
											<option value={50}>50</option>
											<option value={100}>100</option>
										</select>
									</Grid>
								</Box>
								<Table size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											<TableCell>
												<Link component="button" onClick={() => {
														handleSort("email")
													}}>
													Email
												</Link>
											</TableCell>
											<TableCell>
												<Link component="button" onClick={() => {
														handleSort("сonnection")
													}}>
														Provider Connection Id
												</Link>
											</TableCell>
											<TableCell>
												Type
												<select value={stateFilter.type} onChange={(val) => handleFilter("type",val.target.value)} className="btn btn-sm btn-outline-secondary dropdown-toggle">
													<option value="">...</option>
													<option value="SALTEDGE" >SALTEDGE {`(${counters.connectionType.saltedge})`}</option>
													<option value="LEANTECH">LEANTECH {`(${counters.connectionType.leantech})`}</option>
													<option value="MULTIPLE">MULTIPLE {`(${counters.connectionType.multiple})`}</option>
												</select>
											</TableCell>
											<TableCell align="left">
												<Link component="button" onClick={() =>{
													setFilter({ ...stateFilter, email: null, connection: null, created: stateFilter.created === ORDER.DESC ? ORDER.ASC : ORDER.DESC })
													handleSort("created", stateFilter.created)
												}}>
													Created At
												</Link>
											</TableCell>
											<TableCell>Deleted At</TableCell>
											<TableCell align="left">
												Status
												<select value={stateFilter.statusUser} onChange={(val) => handleFilter("statusUser", val.target.value )} className="btn btn-sm btn-outline-secondary dropdown-toggle">
													<option value="">...</option>
													<option value="verified" >Verified {`(${counters.verifications.verified})`}</option>
													<option value="not verified">Not verified {`(${counters.verifications.notVerified})`}</option>
													<option value="deleted">Deleted {`(${counters.verifications.deleted})`}</option>
												</select></TableCell>
											<TableCell align="left">
												Device
												<select value={stateFilter.device} onChange={(val) => handleFilter("device",val.target.value)} className="btn btn-sm btn-outline-secondary dropdown-toggle">
													<option value="">...</option>
													<option value="iOS" >IOS {`(${counters.devices.ios})`}</option>
													<option value="Android">Android {`(${counters.devices.android})`}</option>
												</select>
											</TableCell>
											<TableCell align="left">
												App version
												<select value={stateFilter.selectedVersion} onChange={(val) => {
														if (val.target.value === '...') {
															handleFilter( "selectedVersion", null )
														}else
														handleFilter("selectedVersion", val.target.value.split('(')[0])}
													} className="btn btn-sm btn-outline-secondary dropdown-toggle">
													<option value={null}>...</option>
													{appVersions? appVersions.map(appVersion => {
														return (<option key={appVersion.appVersion} value={appVersion.appVersion}>{appVersion.appVersion? `${appVersion.appVersion}`: "no versions"}({appVersion.count})</option>) 
													}): ''}
												</select>
											</TableCell>
											<TableCell align="left">
												Subscription
												<select value={stateFilter.subscription} onChange={(val) => handleFilter("subscription", val.target.value )} className="btn btn-sm btn-outline-secondary dropdown-toggle">
													<option value="">...</option>
													<option value="NEW">NEW {`(${counters.subscriptions.new})`}</option>
													<option value="TRIAL">TRIAL {`(${counters.subscriptions.trial})`}</option>
													<option value="SUBSCRIBED">SUBSCRIBED {`(${counters.subscriptions.subscribed})`}</option>
													<option value="UNSUBSCRIBED">UNSUBSCRIBED {`(${counters.subscriptions.unSubscribed})`}</option>
													<option value="CANCELED">CANCELLED {`(${counters.subscriptions.cancelled})`}</option>
												</select>
											</TableCell>
											<TableCell align="left">
												Next Payment Type
												<select value={stateFilter.nextPayment} onChange={(val) => {handleFilter( "nextPayment", val.target.value )}} className="btn btn-sm btn-outline-secondary dropdown-toggle">
													<option value="">...</option>
													<option value="YEAR" >Year {`(${counters.nextPaymentTypes.yearly})`}</option>
													<option value="MONTH">Month {`(${counters.nextPaymentTypes.monthly})`}</option>
													<option value="NONE">None {`(${counters.nextPaymentTypes.none})`}</option>
												</select>
											</TableCell>
											<TableCell align="left">
												Type Term
												<select value={stateFilter.typeTerm} onChange={(val) => {handleFilter( "typeTerm", val.target.value )}} className="btn btn-sm btn-outline-secondary dropdown-toggle">
													<option value="">...</option>
													<option value="YEAR" >Year {`(${counters.typeTerm.yearly})`}</option>
													<option value="MONTH">Month {`(${counters.typeTerm.monthly})`}</option>
													<option value="TRIAL">Trial {`(${counters.typeTerm.trial})`}</option>
													<option value="NONE">None {`(${counters.typeTerm.none})`}</option>
												</select>
											</TableCell>
											<TableCell align="left">
												Device Subscription
												<select value={stateFilter.deviceSubscription} onChange={(val) => {handleFilter( "deviceSubscription", val.target.value )}} className="btn btn-sm btn-outline-secondary dropdown-toggle">
													<option value="">...</option>
													<option value="IOS" >iOS {`(${counters.deviceSubscription.iOS})`}</option>
													<option value="ANDROID">android {`(${counters.deviceSubscription.android})`}</option>
													<option value="NONE">None {`(${counters.deviceSubscription.none})`}</option>
												</select>
											</TableCell>
											<TableCell align="left">
												<Link component="button" onClick={()=>{
													setFilteredUsers([...filteredUsers].sort((a, b) => {
														if (a.subscription?.expire && b.subscription?.expire) {
															return moment(a.subscription.expire).isBefore(moment(b.subscription.expire)) ? 1 : -1
														}
														return 0
													}))
												}}>
													End Subscription
												</Link>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
                                            rows?.length !== 0 ? rows?.map((user) => {
												
												return (<TableRow key={user.id}>
													<TableCell component="th" scope="row">
														{user.email? user.email.indexOf('#') != -1 ? user.email.substring(0, user.email.indexOf('#')) : user.email : "" }
													</TableCell>
													{renderConnections(user)}
													<TableCell align="left">
														{moment(user.createdAt).format('MM/DD/YYYY HH:mm')}
													</TableCell>
													<TableCell align="left">
														{user.deletedAt? moment(user.deletedAt).format('MM/DD/YYYY HH:mm'): (
															<span className="green">Active</span>
														)}
													</TableCell>
													<TableCell align="left">
														{
															user.is_verified 
																?<span className="green">Verified</span>
																: user.deletedAt
																	? <span className="red">Deleted</span>
																	: <span className="red">Not verified</span>
														}
													</TableCell>
													<TableCell align="left">
														{user.deviceType}
													</TableCell>
													<TableCell align="left">
														{user.appVersion}
													</TableCell>
													<TableCell align="left">
														{user.subscription ? user.subscription.type : ""}
													</TableCell>
													<TableCell align="left">
														{user.subscription?.next_payment_type_term ?? "-"}
													</TableCell>
													<TableCell align="left">
														{user.subscription ? user.subscription.type_term : ""}
													</TableCell>
													<TableCell align="left">
														{user.subscription ? user.subscription.device_type : ""}
													</TableCell>
													<TableCell align="left">
														{user.subscription ? moment(user.subscription.expire).format('MM/DD/YYYY HH:mm') : ""}
													</TableCell>
												</TableRow>
											)}) : (
												<TableRow>
													<TableCell colSpan={12} align="center">
														No data
													</TableCell>
												</TableRow>
											)
										}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					</div>
					<Box display="flex" justifyContent="center">
						<PaginationComponent
							pageCount={pagination.pageCount}
							paginationHandler={paginationHandler}
						/>
					</Box>
				</main>
			</section>
			<Window handleClose={handleCloseModal} open={!!modalData} isBig>
				<div>
					<TableContainer component={Paper}>
						<Table size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell>Connection id</TableCell>
									<TableCell>Type</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Array.isArray(modalData) && modalData.map(connection => (
									<TableRow key={connection.providerConnectionId}>
										<TableCell><Link to={PATH.home}>{connection.providerConnectionId}</Link>
										</TableCell>
										<TableCell>{connection.type}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<div className="buttonsModalWrapper">
						<Button variant="contained" color="secondary" onClick={handleCloseModal}>
							Cancel
						</Button>
					</div>
				</div>
			</Window>
		</Box >
	);
}

export default Users;
