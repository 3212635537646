import React from 'react'
import PropTypes from 'prop-types'
import styles from './style.module.scss';

function Popup({title, children, setClosure}) {
  return (
    <div className={styles.popupBackground} onClick={()=>setClosure()}>
        <div className={styles.popup} onClick={event=>event.stopPropagation()}>
            <h3 className={styles.popupTitle}>{title}</h3>
            <div className={styles.popupContent}>
                {children}
            </div>
        </div>
    </div>
  )
}

Popup.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    setClosure: PropTypes.func.isRequired
}

export default Popup;