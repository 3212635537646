import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import classnames from 'classnames';

function getModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3)
	},
	bigModal: {
		width: 700,
	}
}));

export default function Window({ children, handleClose, open, isBig = false }) {
	const classes = useStyles();
	const [modalStyle] = React.useState(getModalStyle);

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div
					style={modalStyle}
					className={classnames(classes.paper, { [classes.bigModal]: isBig })}
				>
					{children}
				</div>
			</Modal>
		</div>
	);
}
Window.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	handleClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	isBig: PropTypes.bool,
};
