import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import LoginForm from '../../forms/login';
import auth from '../../utills/auth';
import { PATH } from '../../utills/Path';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { clearUserError, login } from '../../redux/user/actions';
function Login({ history }) {
	const { loading, error } = useSelector((state) => state.user);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearUserError());
		return () => dispatch(clearUserError());
	}, [dispatch]);
	const loginHandler = (data) => {
		// ! CHECK
		data.skip_mfa = true; 
		dispatch(
			login(data, (response) => {
				if (response?.is_verified) {
					auth.login(() => {
						history.push(PATH.home);
					});
				} else {
					history.push(PATH.codeVerification);
				}
			})
		);
	};
	return <LoginForm error={error} isLoading={loading} loginHandler={loginHandler} />;
}

Login.propTypes = {
	history: PropTypes.object.isRequired
};

export default withRouter(Login);
