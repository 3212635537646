import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import styles from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	LinearProgress,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Box,
} from '@material-ui/core';
import { fetchConnectionHistory, fetchBankHistory } from '../../redux/history/actions';
import { Link, useLocation, useHistory} from 'react-router-dom';
import queryString from 'query-string';
import PaginationComponent from '../../components/Pagination/indedx';
import api from '../../utills/api';

function History() {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const loading = useSelector((state) => state.history.loading);
	const connectionHistory = useSelector((state) => state.history.connectionHistory);
	const bankHistory = useSelector((state) => state.history.bankHistory);
	const { connectionId, connectionProviderId,identifier, email, bank ,bankId} = queryString.parse(location.search);
	
	const [data, setData] = useState({
		connectionId: '',
		connectionProviderId: '',
		email: '',
		user_id: '',
		connectionHistory: [],
		bank: '',
		bankHistory
	});

	const [filter, setFilter] = useState({
		result: "",
		limit: 50,
	});
		
	const [pagination, setPagination] = useState({
		page: 1,
		pageCount: 0,
	});

	const [sortCriteria, setSortCriteria] = useState({
		sortBy: "",
		sortOrder: "",
	});

	const [rows, setRows] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	
	useEffect(() => {
		setRows([])
		connectionId && dispatch(fetchConnectionHistory(connectionId));
		bankId && dispatch(fetchBankHistory(bankId));
	}, []);

	useEffect(() => {
		if (connectionId){
			setData({
				...data,
				connectionId,
				connectionProviderId,
				identifier,
				email,
				connectionHistory,
				bankHistory
			});
		}
	}, [dispatch]);
	
	useEffect(() => {
		setData({
			...data,
			connectionId,
			connectionProviderId,
			email,
			connectionHistory,
			bank,
			bankHistory
		});
	}, [bankHistory])
	
	useEffect(() => {
		setData({
			...data,
			connectionId,
			connectionProviderId,
			email,
			identifier,
			connectionHistory,
			bank,
			bankHistory
		});
	},[connectionHistory])

	useEffect(() => {
		console.log(data);
	}, [data])
	

	useEffect(() => {
		if (connectionId) {
			setRows(data.connectionHistory);
		}
		
		if (bankId) {
			const page = location.search ? queryString.parse(location.search).page : 1;
			const pageCount = Math.ceil(data.bankHistory.length / filter.limit);
			
			setPagination({
				...pagination,
				pageCount,
				page,
			});
			
			filterData();
		}
	}, [data]);
	
	useEffect(() => {
		paginationHandler(1);
	}, [filteredData]);
	
	useEffect(() => {
		filterData();
	}, [filter]);	

	const filterHandler = (key, value) => {
		setFilter({
			...filter,
			[key]: value,
		});
	}

	const downloadExcel = (id) => {
		console.log(id);
		api.dashboard.syncLeanDataRawDownload(id).catch((err) => {
			console.log(err);
		}).then(res => {
			const aElement = document.createElement('a');
			aElement.setAttribute('download', 'leanResponse.xlsx');
			const href = URL.createObjectURL(res);
			aElement.href = href;
			aElement.setAttribute('target', '_blank');
			aElement.click();
			URL.revokeObjectURL(href);
		});
	}
	
	const paginationHandler = (page) => {
		setPagination({
			...pagination,
			pageCount: Math.ceil(filteredData.length / filter.limit),
			page,
		});

		const searchParams = new URLSearchParams(location.search);
		searchParams.set('page', page);
		history.push({
			search: searchParams.toString(),
		});

		let paginated = [...filteredData].slice(
			(page - 1) * filter.limit,
			page * filter.limit
		);

		setRows(paginated);
	};

	const handleSort = (column) => {
		const sortOrder = sortCriteria.sortOrder === "asc" ? "desc" : "asc";
		setSortCriteria({
			sortBy: column,
			sortOrder,
		});
		setFilteredData([...filteredData].sort((a, b) => {
			if (sortOrder === "asc") {
				return a[column] > b[column] ? 1 : -1;
			} else {
				return a[column] < b[column] ? 1 : -1;
			}
		}));
	}

	const handleSortDate = (column) => {
		const sortOrder = sortCriteria.sortOrder === "asc" ? "desc" : "asc";
		setSortCriteria({
			sortBy: column,
			sortOrder,
		});
		setFilteredData([...filteredData].sort((a, b) => {
			if (sortOrder === "asc") {
				return new Date(a[column]) > new Date(b[column]) ? 1 : -1;
			} else {
				return new Date(a[column]) < new Date(b[column]) ? 1 : -1;
			}
		}));
	}

	const filterData = () => {
		let filtered = [...data.bankHistory].filter((connection) => {
			if (filter.result.length > 0){
				if (filter.result === "SUCCESS" && !connection.success_at) return;
				if (filter.result === "FAIL" && !connection.fail_at) return;
				if (filter.result === "PROCESSING" && !(!connection.success_at && !connection.fail_at)) return;
			} 

			if (!connection.connection?.providerConnectionId) return;

			return connection;
		});
		setFilteredData(filtered);
	}

    return (
        <div>
			<Header />
			<section>
				<Aside />
				<main className="content">
					{loading && <LinearProgress color="secondary" />}
					{connectionId 
					? <>
						<h2 className={styles.basicTitle}>Connection History</h2>
						<h4 className={styles.basicSubTitle}>
							Email: <span className={styles.content}> {email} </span>
						</h4>
						<h4 className={styles.basicSubTitle}>
							Connection Provider Id: <span className={styles.content}> {connectionProviderId} </span> 
						</h4>
						<h4 className={styles.basicSubTitle}>
							Identifier: <span className={styles.content}> {identifier} </span> 
						</h4>
						<h4 className={styles.basicSubTitle}>
							Bank: <span className={styles.content}> {bank} </span> 
						</h4>
					</>
					:<>
						<h2 className={styles.basicTitle}>Bank History</h2>
						<div className={styles.header}>
							<h4 className={styles.basicSubTitle}>
								Bank: <span className={styles.content}> {bank} </span>
							</h4>
							<h4 className={styles.basicSubTitle}>
								Results per page: 
							</h4>
							<select
							value={filter.limit}
							onChange={(e)=>{
								filterHandler("limit", parseInt(e.target.value));
							}}>
								<option value={10}>10</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
							</select>
						</div>
					</>
					}
					<div className={styles.wrapper}>
						<Box display="flex">
							<div className={styles.column}>
								<TableContainer component={Paper}>
									<Table>
										<TableHead>
											<TableRow>
												{!connectionId && <TableCell align="left">
													<Link onClick={()=>handleSort("email")}>
														Email
													</Link>
												</TableCell>}
												{!connectionId && <TableCell align="left">
													<Link onClick={()=>handleSort("providerConnectionId")}>
														Provider Connection Id
													</Link>
												</TableCell>}
												<TableCell align="left">Event name</TableCell>
												<TableCell align="left">
													<Link onClick={()=>handleSortDate("start_at")}>
														Time
													</Link>
												</TableCell>
												<TableCell align="left">
													<p>Result</p>
													<select
														name="result"
														value={filter.result}
														onChange={(e) =>filterHandler("result", e.target.value)}
													>
														<option value="">...</option>
														<option value="SUCCESS">Success</option>
														<option value="FAIL">Fail</option>
														<option value="PROCESSING">Processing</option>
													</select>
												</TableCell>
												<TableCell align="left">Reason of fail from Lean</TableCell>
												<TableCell align="left">Crunch additional comment</TableCell>
												<TableCell align="left">Action</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{
												rows.length === 0 
												? (
													<TableRow>
														<TableCell colSpan={6} align="center">
															No data
														</TableCell>
													</TableRow>
												)
												: bankId && rows?.map((row) => {
													return (
														<TableRow key={row.id}>
															{bankId && <TableCell align="left">{row.email}</TableCell>}
															{bankId && <TableCell align="left">{row.connection?.providerConnectionId ?? "Not Provided"}</TableCell>}
															<TableCell align="left">{row.type}</TableCell>
															<TableCell align="left">
																{
																	row.fail_at 
																	? `${row.fail_at.split("T")[0]} - ${row.fail_at.split("T")[1].slice(0,8)}`
																	: row.success_at 
																		? `${row.success_at.split("T")[0]} - ${row.success_at.split("T")[1].slice(0,8)}`
																		: "..."
																}
															</TableCell>
															<TableCell align="left">
																{
																	row.status 
																	? row.status 
																	: (!row.success_at)
																		? (!row.fail_at)
																			? "PROCESSING" 
																			: "FAIL"
																		: "SUCCESS"
																}
															</TableCell>
															<TableCell align="left">{row.information_service_message ?? "-"}</TableCell>
															<TableCell align="left">{row.information_action_message ?? "-"}</TableCell>
															<TableCell align="left">
																<Link onClick={()=>downloadExcel(row.id)}>
																	Excel
																</Link>
															</TableCell>
														</TableRow>
													)
												})
											}


											
											
											{
												connectionId && (rows.length !== 0 ? rows?.map((row) => {

													if (filter.result.length > 0){
														if (filter.result === "SUCCESS" && !row.success_at) return;
														if (filter.result === "FAIL" && !row.fail_at) return;
													}

													if (!row.connection?.providerConnectionId) return;

													return (
													<TableRow key={row.id}>
														{bankId && <TableCell align="left">{row.email}</TableCell>}
														{bankId && <TableCell align="left">{row.connection?.providerConnectionId}</TableCell>}
														<TableCell align="left">{row.type}</TableCell>
														<TableCell align="left">
															{
																row.success_at 
																? `${row.success_at.split("T")[0]} - ${row.success_at.split("T")[1].slice(0,8)}`
																: row.fail_at 
																	? `${row.fail_at.split("T")[0]} - ${row.fail_at.split("T")[1].slice(0,8)}`
																	: "No time"
															}
														</TableCell>
														<TableCell align="left">{row.status ? row.status : row.success_at ? "SUCCESS" : "FAIL"}</TableCell>
														<TableCell align="left">{row.information_service_message ?? "-"}</TableCell>
														<TableCell align="left">{row.information_action_message ? row.information_action_message : "-"}</TableCell>
														<TableCell align="left">
																<Link onClick={()=>downloadExcel(row.id)}>
																	Excel
																</Link>
														</TableCell>
													</TableRow>
												)}
												):(
													<TableRow>
														<TableCell align="center" colSpan={7}>No data</TableCell>
													</TableRow>
												))
											}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						</Box>
						<div className={styles.paginationWrapper}>
							<PaginationComponent pageCount={pagination.pageCount} paginationHandler={paginationHandler} >

							</PaginationComponent>
						</div>
					</div>
				</main>
			</section>
		</div>
    );
}

export default History;
