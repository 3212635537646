import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import auth from '../../utills/auth';
import { PATH } from '../../utills/Path';
import ResetPasswordForm from '../../forms/resetPassword';

function ResetPassword({ history }) {
	const resetPasswordHandler = () => {
		auth.login((data) => {
			console.log(data);
			history.push(PATH.signin);
		});
	};
	return <ResetPasswordForm resetPasswordHandler={resetPasswordHandler} />;
}

ResetPassword.propTypes = {
	history: PropTypes.object.isRequired
};

export default withRouter(ResetPassword);
