import {
	GET_ALL_BANK_LIST,
	GET_ALL_BANK_SUCCESS,
	GET_ALL_BANK_ERROR,
} from './constants';

const initState = {
	isLogin: false,
	loading: false,
	error: null,
	banks: null,
};

export default (state = initState, action) => {
	const {
		type,
		payload
	} = action;
	switch (type) {
		case GET_ALL_BANK_LIST:
			return {
				...state,
				loading: true
			};
		case GET_ALL_BANK_ERROR:
			return {
				...state,
				loading: false,
					error: payload
			};
		case GET_ALL_BANK_SUCCESS:
			return {
				...state,
				loading: false,
					banks: payload
			};
		default:
			return {
				...state
			};
	}
}