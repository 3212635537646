import {
	GET_ADMINS_REQUEST,
	GET_ADMINS_SUCCESS,
	GET_ADMINS_ERROR,
	DELETE_ADMIN_REQUEST,
	DELETE_ADMIN_SUCCESS,
	DELETE_ADMIN_ERROR,
	EDIT_ADMIN_REQUEST,
	EDIT_ADMIN_SUCCESS,
	EDIT_ADMIN_ERROR,
	APPROVE_ADMIN_REQUEST,
	APPROVE_ADMIN_SUCCESS,
	APPROVE_ADMIN_ERROR,
	GET_PENDING_ADMINS_REQUEST,
GET_PENDING_ADMINS_SUCCESS,
GET_PENDING_ADMINS_ERROR
} from './constants';

const initState = {
	loading: false,
	admins: [],
	pending_admins:[],
	count:0,
	page:0,
	pageCount:0,
	total:0
};

export default (state = initState, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_ADMINS_REQUEST:
		case DELETE_ADMIN_REQUEST:
		case EDIT_ADMIN_REQUEST:
		case APPROVE_ADMIN_REQUEST:
		case GET_PENDING_ADMINS_REQUEST:
			return {
				...state,
				loading: true
			};
		case GET_ADMINS_SUCCESS:
			return {
				...state,
				admins: payload.data,
				count: payload.count,
				page: payload.page,
				pageCount: payload.pageCount,
				total: payload.total,
				loading: false
			};
		case DELETE_ADMIN_SUCCESS:
			return {
				...state,
				loading: false
			};
		case EDIT_ADMIN_SUCCESS:
			return {
				...state,
				loading: false
			};
		case APPROVE_ADMIN_SUCCESS:
			return {
				...state,
				loading: false
			};
		case GET_PENDING_ADMINS_SUCCESS:
			return {
				...state,
				loading: false,
				pending_admins: payload.data
			};
		case GET_ADMINS_ERROR:
		case DELETE_ADMIN_ERROR:
		case EDIT_ADMIN_ERROR:
		case APPROVE_ADMIN_ERROR:
		case GET_PENDING_ADMINS_ERROR:
			return {
				...state,
				loading: false
			};
		default:
			return {
				...state
			};
	}
};
