import React from 'react';
import Logout from '../Logout';
import styles from './style.module.scss';

function Header() {
	return <header className={styles.wrapper}>
    <Logout />
  </header>;
}

export default Header;
