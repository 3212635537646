import api from '../../utills/api';
import { CONNECTION_STATUS } from '../../utills/dashboard';
import {
	GET_CONNECTION_COUNT_TRUE_REQUEST,
	GET_CONNECTION_COUNT_TRUE_SUCCESS,
	GET_CONNECTION_COUNT_TRUE_ERROR,
	GET_CONNECTION_COUNT_FALSE_REQUEST,
	GET_CONNECTION_COUNT_FALSE_SUCCESS,
	GET_CONNECTION_COUNT_FALSE_ERROR,
	GET_TRANSACTION_COUNT_REQUEST,
	GET_TRANSACTION_COUNT_SUCCESS,
	GET_TRANSACTION_COUNT_ERROR,
	GET_TOTAL_CONNECTIONS_COUNT_REQUEST,
	GET_TOTAL_CONNECTIONS_COUNT_SUCCESS,
	GET_TOTAL_CONNECTIONS_COUNT_ERROR,
	GET_TOTAL_ACCOUNTS_COUNT_REQUEST,
	GET_TOTAL_ACCOUNTS_COUNT_SUCCESS,
	GET_TOTAL_ACCOUNTS_COUNT_ERROR,
	GET_CONNECTIONS_REQUEST,
	GET_CONNECTIONS_SUCCESS,
	GET_CONNECTIONS_ERROR,
	GET_TOTAL_USERS_STATISTICS,
	GET_SET_NEW_CONNECTIONS
} from './constants';

const getConnectionCountTrueRequest = () => ({
	type: GET_CONNECTION_COUNT_TRUE_REQUEST
});

const getConnectionCountTrueError = (payload) => ({
	type: GET_CONNECTION_COUNT_TRUE_ERROR,
	payload
});

const getConnectionCountTrueSuccess = (payload) => ({
	type: GET_CONNECTION_COUNT_TRUE_SUCCESS,
	payload
});

export const getSuccessfulConnectionCount = () => (dispatch) => {
	dispatch(getConnectionCountTrueRequest());
	api.dashboard
		.getConnectionCountByStatus(CONNECTION_STATUS.success)
		.then((data) => {
			if (!data.error) {
				dispatch(getConnectionCountTrueSuccess(data));
			} else {
				dispatch(getConnectionCountTrueError(data));
			}
		})
		.catch((err) => {
			dispatch(getConnectionCountTrueError(err));
		});
};

const getConnectionCountFalseRequest = () => ({
	type: GET_CONNECTION_COUNT_FALSE_REQUEST
});

const getConnectionCountFalseError = (payload) => ({
	type: GET_TRANSACTION_COUNT_ERROR,
	payload
});

const getConnectionCountFalseSuccess = (payload) => ({
	type: GET_TRANSACTION_COUNT_SUCCESS,
	payload
});

export const getFailedConnectionCount = () => (dispatch) => {
	dispatch(getConnectionCountFalseRequest());
	api.dashboard
		.getConnectionCountByStatus(CONNECTION_STATUS.failed)
		.then((data) => {
			if (!data.error) {
				dispatch(getConnectionCountFalseSuccess(data));
			} else {
				dispatch(getConnectionCountFalseError(data));
			}
		})
		.catch((err) => {
			dispatch(getConnectionCountFalseError(err));
		});
};

const getTransactionCountRequest = () => ({
	type: GET_TRANSACTION_COUNT_REQUEST
});

const getTransactionCountError = (payload) => ({
	type: GET_CONNECTION_COUNT_FALSE_ERROR,
	payload
});

const getTransactionCountSuccess = (payload) => ({
	type: GET_CONNECTION_COUNT_FALSE_SUCCESS,
	payload
});

export const getTransactionCount = () => (dispatch) => {
	dispatch(getTransactionCountRequest());
	api.dashboard
		.getTransactionCount()
		.then((data) => {
			if (!data.error) {
				dispatch(getTransactionCountSuccess(data));
			} else {
				dispatch(getTransactionCountError(data));
			}
		})
		.catch((err) => {
			dispatch(getTransactionCountError(err));
		});
};
const getTotalConnectionCountRequest = () => ({
	type: GET_TOTAL_CONNECTIONS_COUNT_REQUEST
});

const getTotalConnectionCountError = (payload) => ({
	type: GET_TOTAL_CONNECTIONS_COUNT_ERROR,
	payload
});

const getTotalConnectionCountSuccess = (payload) => ({
	type: GET_TOTAL_CONNECTIONS_COUNT_SUCCESS,
	payload
});

const getTotalUsersStatistics = (payload) => ({
	type: GET_TOTAL_USERS_STATISTICS,
	payload
});


export const getTotalUserStatistics = () => (dispatch) => {
	api.dashboard
		.getTotalUserStatistics()
		.then((data) => {
			if (!data.error) {
				dispatch(getTotalUsersStatistics(data));
			} else {
				dispatch(getTotalConnectionCountError(data));
			}
		})
		.catch((err) => {
			dispatch(getTotalConnectionCountError(err));
		});
};

export const getTotalConnectionCount = () => (dispatch) => {
	dispatch(getTotalConnectionCountRequest());
	api.dashboard
		.getTotalConnectionsCount()
		.then((data) => {
			if (!data.error) {
				dispatch(getTotalConnectionCountSuccess(data));
			} else {
				dispatch(getTotalConnectionCountError(data));
			}
		})
		.catch((err) => {
			dispatch(getTotalConnectionCountError(err));
		});
};
const getTotalAccountsCountRequest = () => ({
	type: GET_TOTAL_ACCOUNTS_COUNT_REQUEST
});

const getTotalAccountsCountError = (payload) => ({
	type: GET_TOTAL_ACCOUNTS_COUNT_ERROR,
	payload
});

const getTotalAccountsCountSuccess = (payload) => ({
	type: GET_TOTAL_ACCOUNTS_COUNT_SUCCESS,
	payload
});

export const getTotalAccountCount = () => (dispatch) => {
	dispatch(getTotalAccountsCountRequest());
	api.dashboard
		.getTotalAccountsCount()
		.then((data) => {
			if (!data.error) {
				dispatch(getTotalAccountsCountSuccess(data));
			} else {
				dispatch(getTotalAccountsCountError(data));
			}
		})
		.catch((err) => {
			dispatch(getTotalAccountsCountError(err));
		});
};
const getConnectionsRequest = () => ({
	type: GET_CONNECTIONS_REQUEST
});

const getConnectionsError = (payload) => ({
	type: GET_CONNECTIONS_ERROR,
	payload
});

export const getConnectionsSuccess = (payload) => ({
	type: GET_CONNECTIONS_SUCCESS,
	payload
});

export const getSetNewConnections = (payload) => ({
	type: GET_SET_NEW_CONNECTIONS,
	payload
});

export const getConnections =
	(update) =>
		(dispatch) => {
			dispatch(getConnectionsRequest());
			api.dashboard
				.getConnections(update)
				.then((data) => {
					if (!data.error) {
						dispatch(getConnectionsSuccess(data));
					} else {
						dispatch(getConnectionsError(data));
					}
				})
				.catch((err) => {
					dispatch(getConnectionsError(err));
				});
		};
