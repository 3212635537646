import moment from 'moment';
import { getUserTokenFromStorage, removeUserTokenToStorage, setUserTokenToStorage } from './user';
import api, { BASE_URL } from './api.js';

class Auth {
	constructor() {
		const { refresh_token, access_token, is_verified } = getUserTokenFromStorage();
		this.Authenticated = is_verified && refresh_token && access_token;
	}

	login(cb) {
		this.Authenticated = true;
		cb();
	}

	logout(cb) {
		removeUserTokenToStorage();
		this.Authenticated = false;
		cb();
	}

	isAuthenticated() {
		return this.Authenticated;
	}
	async checkToken(url) {
		if ((url && !url.includes(BASE_URL)) || (url && url.includes('refresh-token'))) {
			return;
		}
		const access_token = localStorage.getItem('access_token');
		if (access_token) {
			const payload = JSON.parse(atob(access_token.split('.')[1]));
			payload.exp * 1000;
			const diff = payload.exp - moment().unix();
			if (diff < 0) {
				const refresh_token = localStorage.getItem('refresh_token');
					await api.user
						.refresh(refresh_token)
						.then((res) => res.json())
						.then((data) => {
							if (data && !data.error && data.statusCode !== 401) {
								setUserTokenToStorage(data);
							}
						})
						.catch((err) => err);
			}
		}
	}
}

export default new Auth();
