import React, { useEffect, useState } from 'react';
import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Box,
	Button,
	Link,
} from '@material-ui/core';
import Aside from '../../components/Aside';
import Header from '../../components/Header';
import styles from './style.module.scss';
import Window from "../../components/Modal";
import { PATH } from '../../utills/Path';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalUserStatistics } from '../../redux/dashboard/actions';
import { roundPercentage } from '../../utills/dashboard';

function Statistics() {
	let dashboard = useSelector((state) => state.dashboard);

	const [modalData, setModalData] = useState(null);
	const dispatch = useDispatch();

	// dashboard = {

	// };

	useEffect(() => {
		dispatch(getTotalUserStatistics());

	}, [dispatch]);

	const handleCloseModal = () => {
		setModalData(null);
	}

	return (
		<Box>
			<Header />
			<section>
				<Aside />
				<main className="content">
					<div className="wrapper">
						<div className={styles.table}>
							<header className={styles.header}>
								<div className={styles.header_left}>
									<h5 className={styles.title}>Users Statistics</h5>
									<div className={styles.header_left_wrapper}>
										<div className={styles.dayWrapper}>
											<ul>
												<li>
													<h5 className={styles.small_title}>Total Number of Users</h5>
													<h4>{dashboard.userCountAll}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total users with +</h5>
													<h4 >{dashboard.userWithPlus}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Realistic Number of Users</h5>
													<h4>{dashboard.totalRealisticUsers}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Numbers of Users with Bank Connections</h5>
													<h4>{dashboard.userConnectionCountAllUniq}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Users with Bank Connections</h5>
													<h4>{roundPercentage(dashboard.userConnectionCountAllUniq / (dashboard.totalRealisticUsers)) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Realistic users up to last month</h5>
													<h4>{dashboard.userToLastMonth}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Increase/decrease in Number of Users from last month so far</h5>
													<h4>{dashboard.userLastMonth}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage Increase/decrease in Number of Users from last month </h5>
													<h4>{(roundPercentage((dashboard.userLastMonth) / (dashboard.userToLastMonth))) + "%"}</h4>
												</li>
											</ul>
										</div>
									</div>
									{/* ///////////////////////////////// */}
									<h5 className={styles.title}>Subscription Dashboard</h5>
									<div className={styles.header_left_wrapper}>
										<div className={styles.dayWrapper}>
											<ul>
												<li>
													<h5 className={styles.small_title}>Total Realistic Number of Users</h5>
													<h4>{dashboard.totalRealisticUsers}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total number of Trial Users</h5>
													<h4 >{dashboard.userWithTrial}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Trial Users </h5>
													<h4>{roundPercentage(dashboard.userWithTrial / (dashboard.totalRealisticUsers)) + '%'}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total number of basic users</h5>
													<h4>{dashboard.userWithUnsubscribed}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of basic users </h5>
													<h4>{roundPercentage((dashboard.userWithUnsubscribed) / (dashboard.totalRealisticUsers)) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Subscribed Users </h5>
													<h4>{+dashboard.userSubscriptionWithMonthly + +dashboard.userSubscriptionWithYearly}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Subscribed Users</h5>
													<h4>{roundPercentage((+dashboard.userSubscriptionWithMonthly + +dashboard.userSubscriptionWithYearly) / dashboard.totalRealisticUsers) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Subscribed users - monthly</h5>
													<h4>{dashboard.userSubscriptionWithMonthly}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Subscribed users - Yearly</h5>
													<h4 >{dashboard.userSubscriptionWithYearly}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Ratio of Monthly to Yearly subscribed users</h5>
													<h4>{roundPercentage(dashboard.userSubscriptionWithMonthly / dashboard.userSubscriptionWithYearly) / 100}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Subscriptions up to last month</h5>
													<h4 >{dashboard.subscriptionUserToLastMonth}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Increase/decrease in Number of subscriptions from last month so far </h5>
													<h4>{(dashboard.subscriptionUserLastMonth)}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage Increase/decrease in Number of Users from last month</h5>
													<h4>{roundPercentage((dashboard.subscriptionUserLastMonth) / (dashboard.subscriptionUserToLastMonth)) + "%"}</h4>
												</li>
											</ul>
										</div>
									</div>
									{/* ///////////////////////////////// */}
									<h5 className={styles.title}>Connection Dashboard</h5>
									<div className={styles.header_left_wrapper}>
										<div className={styles.dayWrapper}>
											<ul>
												<li>
													<h5 className={styles.small_title}>Total Numbers of Users with Bank Connections </h5>
													<h4>{dashboard.userConnectionCountAllUniq}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections</h5>
													<h4 >{dashboard.userConnectionCountAll}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Average Bank Connections per user with a bank connection</h5>
													<h4>{roundPercentage(dashboard.userConnectionCountAll / dashboard.userConnectionCountAllUniq) / 100}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Lean Connections</h5>
													<h4>{dashboard.userConnectionWithLeantech}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Lean Connections</h5>
													<h4>{roundPercentage(dashboard.userConnectionWithLeantech / dashboard.userConnectionCountAll) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Saltedge Connections</h5>
													<h4>{dashboard.userConnectionWithSaltedge}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Saltedge Connections</h5>
													<h4>{roundPercentage(dashboard.userConnectionWithSaltedge / dashboard.userConnectionCountAll) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Users with Lean Connections only</h5>
													<h4>{(dashboard.userConnectionWithLeantechUniq)}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Users with Saltedge Connections only</h5>
													<h4>{dashboard.userConnectionWithSaltedgeUniq}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Users with both Lean & Saltedge Connections</h5>
													<h4 >{+dashboard.userConnectionWithBoth}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Percentage of Subscribed Users with at least 1 Bank Connection</h5>
													<h4>{roundPercentage((+dashboard.userSubscribeHaveConnection)/(+dashboard.userSubscriptionWithMonthly + +dashboard.userSubscriptionWithYearly)) + "%"}</h4>

												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections up to last month</h5>
													<h4 >{dashboard.connectionUpLastMonth}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Increase/decrease in Number of Bank Connections from last month so far</h5>
													<h4>{dashboard.connectionLastMonth}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage Increase/decrease in Number of Bank Connections from last month</h5>
													<h4 >{roundPercentage(dashboard.connectionLastMonth / dashboard.connectionUpLastMonth) + '%'}</h4>
												</li>
											</ul>
										</div>
									</div>
									{/* ///////////////////////////////// */}
									<h5 className={styles.title}>Connection Refreshes Dashboard</h5>
									<div className={styles.header_left_wrapper}>
										<div className={styles.dayWrapper}>
											<ul>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections</h5>
													<h4>{dashboard.userConnectionCountAll}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections from Subscribed Users</h5>
													<h4 >{+dashboard.userSubscriptionConnectionWithYearly + +dashboard.userSubscriptionConnectionWithMonthly}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Bank Connections from Subscribed Users</h5>
													<h4>{roundPercentage((+dashboard.userSubscriptionConnectionWithYearly + +dashboard.userSubscriptionConnectionWithMonthly) / dashboard.userConnectionCountAll) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections from Trial Users</h5>
													<h4>{dashboard.userConnectionWithTrial}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Bank Connections from Trial Users</h5>
													<h4>{roundPercentage(dashboard.userConnectionWithTrial / (dashboard.userConnectionCountAll)) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections from Unsubscribed or Basic Users</h5>
													<h4>{dashboard.userConnectionWithUnsubscribed}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Bank Connections from Unsubscribed or Basic Users</h5>
													<h4>{roundPercentage(dashboard.userConnectionWithUnsubscribed / dashboard.userConnectionCountAll) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank connections</h5>
													<h4>{(+dashboard.userSubscriptionConnectionWithYearly + +dashboard.userSubscriptionConnectionWithMonthly) + +dashboard.userConnectionWithTrial}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank Connections with Autorefresh</h5>
													<h4>{dashboard.connectionsHaveAutorefresh}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Active Bank Connections with Autorefresh</h5>
													<h4>{roundPercentage(dashboard.connectionsHaveAutorefresh / ((+dashboard.userSubscriptionConnectionWithYearly + +dashboard.userSubscriptionConnectionWithMonthly) + +dashboard.userConnectionWithTrial)) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank Connections with non-Autorefresh</h5>
													<h4 >{dashboard.connectionsNonAutorefresh}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Active Bank Connections with non-Autorefresh</h5>
													<h4>{roundPercentage(dashboard.connectionsNonAutorefresh / (+dashboard.userSubscriptionConnectionWithYearly + +dashboard.userSubscriptionConnectionWithMonthly + +dashboard.userConnectionWithTrial)) + "%"}</h4>
												</li>
											</ul>
										</div>
									</div>
									{/* ///////////////////////////////// */}
									<h5 className={styles.title}>Error Checking Dashboard</h5>
									<div className={styles.header_left_wrapper}>
										<div className={styles.dayWrapper}>
											<ul>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank Connections with Autorefresh</h5>
													<h4>{dashboard.connectionsHaveAutorefresh}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank Connections that were Autorefreshed in the Last 24 hrs</h5>
													<h4 >{dashboard.activeLastAutomaticConnection}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Active Bank Connections that were Autorefreshed in the Last 24 hrs</h5>
													<h4 >{roundPercentage(dashboard.activeLastAutomaticConnection / dashboard.connectionsHaveAutorefresh) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank Connections that were Autorefreshed but have the latest transaction date older than 7 days ago</h5>
													<h4>{dashboard.transactionLatestSevenDay}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank Connections with non-Autorefresh</h5>
													<h4>{dashboard.connectionsNonAutorefresh}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank Connections with non-Autorefresh that were refreshed in the last 24 hours</h5>
													<h4>{dashboard.lastActiveConnectionsNonAutorefresh}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Active Bank Connections with non-Autorefresh that were refreshed in the last 24 hours</h5>
													<h4>{roundPercentage(dashboard.lastActiveConnectionsNonAutorefresh / (dashboard.connectionsNonAutorefresh)) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank Connections with non-Autorefresh that were not refreshed in more than 10 days (240 hours)</h5>
													<h4>{dashboard.allFailNonRefreshedConnection10Days}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Active Bank Connections with non-Autorefresh that were not refreshed in more than 10 days (240 hours)</h5>
													<h4>{roundPercentage(dashboard.allFailNonRefreshedConnection10Days / (dashboard.connectionsNonAutorefresh)) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Attempted Active Bank Connection Refreshes in the last 24 hours (Auto and non-Auto)</h5>
													<h4 >{+dashboard.activeLastAutomaticConnection + +dashboard.lastActiveConnectionsNonAutorefresh}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Failed Active Bank Connection Refreshes in the last 24 hrs</h5>
													<h4>{dashboard.lastFailRefreshedConnection}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Failed Active Bank Connection Refreshes in the last 24 hrs</h5>
													<h4 >{roundPercentage(dashboard.lastFailRefreshedConnection / (+dashboard.activeLastAutomaticConnection + +dashboard.lastActiveConnectionsNonAutorefresh)) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Attempted Active Bank Connection Refreshes All Time</h5>
													<h4>{dashboard.cAttemptedCount}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Failed Active Bank Connection Refreshes All Time</h5>
													<h4>{dashboard.cAttemptedIsFailCount}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Failed Active Bank Connection Refreshes All Time</h5>
													<h4>{roundPercentage(dashboard.cAttemptedIsFailCount / dashboard.cAttemptedCount) + '%'}</h4>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</header>
						</div>
					</div>
				</main>
			</section >
			<Window handleClose={handleCloseModal} open={!!modalData} isBig>
				<div>
					<TableContainer component={Paper}>
						<Table size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell>Connection id</TableCell>
									<TableCell>Type</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Array.isArray(modalData) && modalData.map(connection => (
									<TableRow key={connection.providerConnectionId}>
										<TableCell><Link to={PATH.home}>{connection.providerConnectionId}</Link>
										</TableCell>
										<TableCell>{connection.type}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<div className="buttonsModalWrapper">
						<Button variant="contained" color="secondary" onClick={handleCloseModal}>
							Cancel
						</Button>
					</div>
				</div>
			</Window>
		</Box >
	);
}

export default Statistics;
