import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import { withRouter } from 'react-router-dom';

function PaginationComponent({ history, pageCount, paginationHandler }) {
	const [activePage, setPage] = useState(1);

	useEffect(() => {
			const currentSearchParams = new URLSearchParams(history.location.search);
			const page = parseInt(currentSearchParams.get('page'));
			setPage(page);
			paginationHandler(page);
	}, [history.location.search]);

	const changePageHandler = (e, value) => {
		const currentSearchParams = new URLSearchParams(history.location.search);
		currentSearchParams.set('page', value);
		history.push({
			search: `?${currentSearchParams.toString()}`
		});
		setPage(value);
	};

	return (
		<div>
			{!!pageCount && (
				<Pagination
					defaultPage={activePage}
					count={pageCount}
					variant="outlined"
					shape="rounded"
					onChange={changePageHandler}
				/>
			)}
		</div>
	);
}
PaginationComponent.propTypes = {
	pageCount: PropTypes.number.isRequired,
	paginationHandler: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(PaginationComponent);
