import {
	GET_CONNECTION_COUNT_TRUE_REQUEST,
	GET_CONNECTION_COUNT_TRUE_SUCCESS,
	GET_CONNECTION_COUNT_TRUE_ERROR,
	GET_CONNECTION_COUNT_FALSE_REQUEST,
	GET_CONNECTION_COUNT_FALSE_SUCCESS,
	GET_CONNECTION_COUNT_FALSE_ERROR,
	GET_TRANSACTION_COUNT_REQUEST,
	GET_TRANSACTION_COUNT_SUCCESS,
	GET_TRANSACTION_COUNT_ERROR,
	GET_TOTAL_CONNECTIONS_COUNT_REQUEST,
	GET_TOTAL_CONNECTIONS_COUNT_SUCCESS,
	GET_TOTAL_CONNECTIONS_COUNT_ERROR,
	GET_TOTAL_ACCOUNTS_COUNT_REQUEST,
	GET_TOTAL_ACCOUNTS_COUNT_SUCCESS,
	GET_TOTAL_ACCOUNTS_COUNT_ERROR,
	GET_CONNECTIONS_REQUEST,
	GET_CONNECTIONS_SUCCESS,
	GET_CONNECTIONS_ERROR,
	GET_TOTAL_USERS_STATISTICS,
	GET_SET_NEW_CONNECTIONS
} from './constants';

const initState = {
	loading: false,
	loadingConnection: false,
	successful_connections_count: 0,
	failed_connections_count: 0,
	transactions_count: 0,
	total_connections: 0,
	total_accounts: 0,
	connections: [],
	connections_count: 0,
	connections_page: 0,
	connections_pageCount: 0,
	connections_total: 0
};

export default (state = initState, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_CONNECTION_COUNT_TRUE_REQUEST:
		case GET_CONNECTION_COUNT_FALSE_REQUEST:
		case GET_TRANSACTION_COUNT_REQUEST:
		case GET_TOTAL_CONNECTIONS_COUNT_REQUEST:
		case GET_TOTAL_ACCOUNTS_COUNT_REQUEST:
		case GET_CONNECTIONS_REQUEST:
			return {
				...state,
				loading: true,
				loadingConnection: true,
			};
		case GET_CONNECTION_COUNT_TRUE_SUCCESS:
			return {
				...state,
				loading: false,
				successful_connections_count: payload.countData
			};
		case GET_TOTAL_USERS_STATISTICS:
			return {
				...state,
				loading: false,
				...payload
			};
		case GET_CONNECTION_COUNT_FALSE_SUCCESS:
			return {
				...state,
				loading: false,
				failed_connections_count: payload.countData
			};
		case GET_TRANSACTION_COUNT_SUCCESS:
			return {
				...state,
				loading: false,
				transactions_count: payload.countData
			};
		case GET_TOTAL_CONNECTIONS_COUNT_SUCCESS:
			return {
				...state,
				loadingConnection: false,
				total_connections: payload.countData
			};
		case GET_TOTAL_ACCOUNTS_COUNT_SUCCESS:
			return {
				...state,
				loading: false,
				total_accounts: payload.countData
			};
		case GET_CONNECTIONS_SUCCESS:
			return {
				...state,
				loading: false,
				loadingConnection: false,
				connections: payload,
				connectionsData: payload,
			};
		case GET_SET_NEW_CONNECTIONS:
			return {
				...state,
				loading: false,
				loadingConnection: false,
				connections: payload,
			};
		case GET_CONNECTION_COUNT_TRUE_ERROR:
		case GET_CONNECTION_COUNT_FALSE_ERROR:
		case GET_TRANSACTION_COUNT_ERROR:
		case GET_TOTAL_CONNECTIONS_COUNT_ERROR:
		case GET_TOTAL_ACCOUNTS_COUNT_ERROR:
		case GET_CONNECTIONS_ERROR:
			return {
				...state,
				loading: false
			};
		default:
			return {
				...state
			};
	}
}
