import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import styles from './style.module.scss';
import auth from '../../utills/auth'
import { PATH } from '../../utills/Path'

function Logout({ history }) {
	const logoutHandler = () => {
		auth.logout(() => {
			history.push(PATH.signin);
		});
	};

	return (
		<Button variant="contained" color="primary" className={styles.logoutBtn} onClick={logoutHandler}>
			Logout
		</Button>
	);
}

Logout.propTypes = {
	history: PropTypes.object.isRequired
};

export default withRouter(Logout);