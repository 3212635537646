import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import api from '../../utills/api';
import {Box, CircularProgress, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

function CategoriesSubUserCtg({
    category,
    categories,
    name,
}) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [categoriesSelect, setCategoriesSelect] = React.useState('');

    const handleChange = (event,i, name) => {
        categoriesSelect[i] = {
            id: category.name,
            value: event.target.value
        }

        setCategoriesSelect(categoriesSelect);

        api.categories.changeCategory({
            category_id: name,
            admin_parent_category_id: event.target.value
        });
    };


    useEffect(() => {
        if (name) { 
            setIsLoading(true);
            api.categories
                .getUserCategoriesByName(name)
                .then((res) => {
                    if (res.data) {
                        setIsLoading(false);
                        setData(res.data);
                    }
                })
        }
    }, [name]);

    useEffect(() => {
        if (data) { 
            let categoriesSelect = data.map(category => {
                return {
                    id: category.category_id,
                    value: category.parent_id ?? name
                }
            })

            setCategoriesSelect(categoriesSelect);
            console.log(categoriesSelect);
        }
    }, [data]);

    return isLoading ? (
        <Paper className="fullWidth">
            <Box display="flex" alignItems="center" justifyContent="center" className="fullHeight">
                <CircularProgress color="secondary" />
            </Box>
        </Paper>
    ) : (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell align="left">Total Count</TableCell>
                        <TableCell align="left">Total Users</TableCell>
                        <TableCell align="left">Parent categories</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        <TableRow
                            key={category.name}
                        >
                            <TableCell align="left">
                                {category.name}
                            </TableCell>
                            <TableCell align="left">{category.total_transactions_count}</TableCell>
                            <TableCell align="left">{category.user_count}</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    {!Array.isArray(data) || !data.length ? (
                        <TableRow>
                            <TableCell colSpan={3}>No categories found</TableCell>
                        </TableRow>
                    ) : data.map((subCtg, i) => (
                        <TableRow
                            key={subCtg.name}
                        >
                            <TableCell align="left">
                                {subCtg.name}
                            </TableCell>
                            <TableCell align="left">{subCtg.total_transactions_count}</TableCell>
                            <TableCell align="left">{subCtg.user_count}</TableCell>
                            <TableCell align="left">
                            <FormControl fullWidth>  
                                <InputLabel id="demo-simple-select-label">Category parent</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={categoriesSelect[i]? categoriesSelect[i].value : ''}
                                    label="Category parent"
                                    onChange={(e) => {
                                        handleChange(e,i,subCtg.name)
                                    }}
                                >
                                    {categories.map((c) => (
                                    <MenuItem key={c.value} value={c.value}>{c.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

CategoriesSubUserCtg.propTypes = {
    category: PropTypes.object,
    categories: PropTypes.object,
    name: PropTypes.string.isRequired,
}

export default CategoriesSubUserCtg;