export const GET_ADMINS_REQUEST = 'GET_ADMINS_REQUEST';
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_ERROR = 'GET_ADMINS_ERROR';

export const DELETE_ADMIN_REQUEST = 'DELETE_ADMIN_REQUEST';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR';

export const EDIT_ADMIN_REQUEST = 'EDIT_ADMIN_REQUEST';
export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS';
export const EDIT_ADMIN_ERROR = 'EDIT_ADMIN_ERROR';

export const APPROVE_ADMIN_REQUEST = 'APPROVE_ADMIN_REQUEST';
export const APPROVE_ADMIN_SUCCESS = 'APPROVE_ADMIN_SUCCESS';
export const APPROVE_ADMIN_ERROR = 'APPROVE_ADMIN_ERROR';

export const GET_PENDING_ADMINS_REQUEST = 'GET_PENDING_ADMINS_REQUEST';
export const GET_PENDING_ADMINS_SUCCESS = 'GET_PENDING_ADMINS_SUCCESS';
export const GET_PENDING_ADMINS_ERROR = 'GET_PENDING_ADMINS_ERROR';
