import { toast } from 'react-toastify';
import api from '../../utills/api';
import { CATEGORIES_TYPE } from '../../utills/categories';
import {
	GET_CATEGORIES_REQUEST,
	GET_CATEGORIES_SUCCESS,
	GET_CATEGORIES_ERROR,
	GET_SUB_CATEGORIES_REQUEST,
	GET_SUB_CATEGORIES_SUCCESS,
	GET_SUB_CATEGORIES_ERROR
} from './constants';

const getCategoriesRequest = () => ({
	type: GET_CATEGORIES_REQUEST
});

const getCategoriesError = (payload) => ({
	type: GET_CATEGORIES_ERROR,
	payload
});

const getCategoriesSuccess = (payload, categoryType) => ({
	type: GET_CATEGORIES_SUCCESS,
	payload,
	categoryType
});

export const getCategories = () => (dispatch) => {
	dispatch(getCategoriesRequest());
	api.categories.getCategories({type: CATEGORIES_TYPE.EXPENSE})
		.then((data) => {
			if (!data.error) {
				dispatch(getCategoriesSuccess(data, CATEGORIES_TYPE.EXPENSE));
			} else {
				toast.error('Something Went Wrong');
				dispatch(getCategoriesError(data));
			}
		})
		.catch((err) => {
			toast.error('Something Went Wrong');
			dispatch(getCategoriesError(err));});
	api.categories.getCategories({type: CATEGORIES_TYPE.INCOME})
		.then((data) => {
			if (!data.error) {
				dispatch(getCategoriesSuccess(data, CATEGORIES_TYPE.INCOME));
			} else {
				toast.error('Something Went Wrong');
				dispatch(getCategoriesError(data));
			}
		})
		.catch((err) => {
			toast.error('Something Went Wrong');
			dispatch(getCategoriesError(err));});
};
const getSubCategoriesRequest = () => ({
	type: GET_SUB_CATEGORIES_REQUEST
});

const getSubCategoriesError = (payload) => ({
	type: GET_SUB_CATEGORIES_ERROR,
	payload
});

const getSubCategoriesSuccess = (payload) => ({
	type: GET_SUB_CATEGORIES_SUCCESS,
	payload
});

export const getSubCategories = (id) => (dispatch) => {
	dispatch(getSubCategoriesRequest());
	api.categories.getSubCategories(id)
		.then((data) => {
			if (!data.error) {
				dispatch(getSubCategoriesSuccess(data));
			} else {
				toast.error('Something Went Wrong');
				dispatch(getSubCategoriesError(data));
			}
		})
		.catch((err) => {
			toast.error('Something Went Wrong');
			dispatch(getCategoriesError(err));});
};
