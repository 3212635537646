import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import { PATH } from '../../utills/Path';
import { regexEmail, regexPassword } from '../../utills/validation';
import { CircularProgress } from '@material-ui/core';

export default function RegisterForm({ signUpHandler, isLoading, error }) {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const onSubmit = (data) => {
		signUpHandler(data);
	};

	return (
		<div className={styles.wrapper}>
			<h1 className={styles.title}>Admin Sign up</h1>
			<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
				<div className={styles.row}>
					<input
						placeholder="Enter your name"
						className={styles.input}
						{...register('name', {
							required: 'Expecting a name',
							minLength: {
								value: 2,
								message: '2+ chars',
							}
						})}
					/>
					{errors?.name && <span className={styles.error}>{errors.name.message}</span>}
				</div>
				<div className={styles.row}>
					<input
						placeholder="Enter your mail, e.g. your@email.com"
						className={styles.input}
						{...register('email', {
							required: 'Expecting an email. Should look like “your@email.com”',
							pattern: {
								value: regexEmail,
								message: 'Expecting an email. Should match whitelisted domains',
							},
						})}
					/>
					{errors?.email && <span className={styles.error}>{errors.email.message}</span>}
				</div>
				<div className={styles.row}>
					<input
						placeholder="Create your password"
						className={styles.input}
						type="password"
						{...register('password', {
							required: '8+ chars, mixed-case, digits and special characters',
							pattern: {
								value: regexPassword,
								message: '8+ chars, mixed-case, digits and special characters',
							}
						})}
					/>
					{errors?.password && <span className={styles.error}>{errors.password.message}</span>}

					{error && (
						<section className={styles.errorWrapper}>
							{typeof error.message !== 'string' &&
								error.message.map((err) => (
									<span key={err} className={styles.error}>
										{err}
									</span>
								))}
							{typeof error.message === 'string' && <span className={styles.error}>{error.message}</span>}
						</section>
					)}
				</div>
				<div className={styles.submitWrapper}>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						className={styles.submit}
						disabled={Object.keys(errors).length > 0}
					>
						Sign up
					</Button>
				</div>
				<footer className={styles.footer}>
					<Link to={PATH.signin}>Sign in</Link> <span className={styles.divider}> | </span>
					<Link to={PATH.resetPassword}>Reset Password</Link>
				</footer>
			</form>
			{isLoading && (
				<div className={styles.loaderWrappere}>
					<CircularProgress />
				</div>
			)}
		</div>
	);
}

RegisterForm.propTypes = {
	signUpHandler: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	error: PropTypes.object
};
