import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';
import { PATH } from '../../utills/Path';
import { CircularProgress } from '@material-ui/core';

export default function LoginForm({ loginHandler, isLoading, error }) {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const onSubmit = (data) => {
		loginHandler(data);
	};

	return (
		<div className={styles.wrapper}>
			<h1 className={styles.title}>Admin Sign in</h1>
			<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
				<div className={styles.row}>
					<input placeholder="Enter your mail" className={styles.input} {...register('email', { required: true })} />
				</div>
				<div className={styles.row}>
					<input
						placeholder="Enter your password"
						className={styles.input}
						type="password"
						{...register('password', { required: true })}
					/>
					{(errors.password || errors.email) && (
						<span className={styles.error}>Invalid email or password. Please retry signing in</span>
					)}
					{error && (
						<section className={styles.errorWrapper}>
							{typeof error.message !== 'string' &&
								error.message.map((err) => (
									<span key={err} className={styles.error}>
										{err}
									</span>
								))}
							{typeof error.message === 'string' && <span className={styles.error}>{error.message}</span>}
						</section>
					)}
				</div>
				<div className={styles.submitWrapper}>
					<Button variant="contained" color="primary" type="submit" className={styles.submit}>
						Sign in
					</Button>
				</div>
				<footer className={styles.footer}>
					<Link to={PATH.signup}>Sign up</Link> <span className={styles.divider}> | </span>
					<Link to={PATH.resetPassword}>Reset Password</Link>
				</footer>
			</form>
			{isLoading && (
				<div className={styles.loaderWrapper}>
					<CircularProgress />
				</div>
			)}
		</div>
	);
}

LoginForm.propTypes = {
	loginHandler: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	error: PropTypes.object
};
