import {
	GET_SIGN_UP_REQUEST,
	GET_SIGN_UP_SUCCESS,
	GET_SIGN_UP_ERROR,
	CLEAR_USER_ERROR,
	GET_SIGN_IN_REQUEST,
	GET_SIGN_IN_SUCCESS,
	GET_SIGN_IN_ERROR,
	GET_VERIFY_CODE_REQUEST,
	GET_VERIFY_CODE_SUCCESS,
	GET_VERIFY_CODE_ERROR,
	GET_NEW_CODE_REQUEST,
	GET_NEW_CODE_SUCCESS,
	GET_NEW_CODE_ERROR,
	GET_RESET_PASSWORD_REQUEST,
	GET_RESET_PASSWORD_SUCCESS,
	GET_RESET_PASSWORD_ERROR
} from './constants';

const initState = {
	isLogin:false,
	loading: false,
	error:null,
	user: null
};

export default (state = initState, action) =>{
	const { type, payload } = action;
	switch (type) {
		case CLEAR_USER_ERROR:
			return {
				...state,
				error: null
			};
		case GET_SIGN_UP_REQUEST:
		case GET_SIGN_IN_REQUEST:
		case GET_VERIFY_CODE_REQUEST:
		case GET_NEW_CODE_REQUEST:
		case GET_RESET_PASSWORD_REQUEST:
			return {
				...state,
				loading: true
			};
		case GET_SIGN_UP_ERROR:
		case GET_SIGN_IN_ERROR:
		case GET_VERIFY_CODE_ERROR:
		case GET_NEW_CODE_ERROR:
		case GET_RESET_PASSWORD_ERROR:
			return {
				...state,
				loading: false,
				error: payload
			};
		case GET_SIGN_UP_SUCCESS:
			return {
				...state,
				loading: false
			};
		case GET_SIGN_IN_SUCCESS:
			return {
				...state,
				loading: false,
				user: payload
			};
		case GET_NEW_CODE_SUCCESS:
			return {
				...state,
				loading: false
			};
		case GET_RESET_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false
			};
		case GET_VERIFY_CODE_SUCCESS:
			return {
				...state,
				loading: false
			};
		default:
			return {
				...state
			};
	}
}
