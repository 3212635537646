export const GET_CONNECTION_COUNT_TRUE_REQUEST = 'GET_CONNECTION_COUNT_TRUE_REQUEST';
export const GET_CONNECTION_COUNT_TRUE_SUCCESS = 'GET_CONNECTION_COUNT_TRUE_SUCCESS';
export const GET_CONNECTION_COUNT_TRUE_ERROR = 'GET_CONNECTION_COUNT_TRUE_ERROR';

export const GET_CONNECTION_COUNT_FALSE_REQUEST = 'GET_CONNECTION_COUNT_FALSE_REQUEST';
export const GET_CONNECTION_COUNT_FALSE_SUCCESS = 'GET_CONNECTION_COUNT_FALSE_SUCCESS';
export const GET_CONNECTION_COUNT_FALSE_ERROR = 'GET_CONNECTION_COUNT_FALSE_ERROR';

export const GET_TRANSACTION_COUNT_REQUEST = 'GET_TRANSACTION_COUNT_REQUEST';
export const GET_TRANSACTION_COUNT_SUCCESS = 'GET_TRANSACTION_COUNT_SUCCESS';
export const GET_TRANSACTION_COUNT_ERROR = 'GET_TRANSACTION_COUNT_ERROR';

export const GET_TOTAL_CONNECTIONS_COUNT_REQUEST = 'GET_TOTAL_CONNECTIONS_COUNT_REQUEST';
export const GET_TOTAL_USERS_STATISTICS = 'GET_TOTAL_USERS_STATISTICS';
export const GET_TOTAL_CONNECTIONS_COUNT_ERROR = 'GET_TOTAL_CONNECTIONS_COUNT_ERROR';
export const GET_TOTAL_CONNECTIONS_COUNT_SUCCESS = 'GET_TOTAL_CONNECTIONS_COUNT_SUCCESS';

export const GET_TOTAL_ACCOUNTS_COUNT_REQUEST = 'GET_TOTAL_ACCOUNTS_COUNT_REQUEST';
export const GET_TOTAL_ACCOUNTS_COUNT_SUCCESS = 'GET_TOTAL_ACCOUNTS_COUNT_SUCCESS';
export const GET_TOTAL_ACCOUNTS_COUNT_ERROR = 'GET_TOTAL_ACCOUNTS_COUNT_ERROR';

export const GET_CONNECTIONS_REQUEST = 'GET_CONNECTIONS_REQUEST';
export const GET_CONNECTIONS_SUCCESS = 'GET_CONNECTIONS_SUCCESS';
export const GET_SET_NEW_CONNECTIONS = 'GET_SET_NEW_CONNECTIONS';
export const GET_CONNECTIONS_ERROR = 'GET_CONNECTIONS_ERROR';