import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { PATH } from '../../utills/Path';
import RegisterForm from '../../forms/register';
import { useDispatch, useSelector } from 'react-redux';
import { register, clearUserError } from '../../redux/user/actions';

function Signup({ history }) {
	const { loading, error } = useSelector((state) => state.user);
	const dispatch = useDispatch();

	useEffect(()=>{
		dispatch(clearUserError());
		return () => dispatch(clearUserError());
	},[dispatch]);


	const signUpHandler = (data) => {
		dispatch(
			register(data, () => {
				history.push(PATH.codeVerification);
			})
		);
	};

	return <RegisterForm error={error} isLoading={loading} signUpHandler={signUpHandler} />;
}

Signup.propTypes = {
	history: PropTypes.object.isRequired
};

export default withRouter(Signup);
