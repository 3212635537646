export const GET_SIGN_UP_REQUEST = 'GET_SIGN_UP_REQUEST';
export const GET_SIGN_UP_SUCCESS = 'GET_SIGN_UP_SUCCESS';
export const GET_SIGN_UP_ERROR = 'GET_SIGN_UP_ERROR';

export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR';


export const GET_SIGN_IN_REQUEST = 'GET_SIGN_IN_REQUEST';
export const GET_SIGN_IN_SUCCESS = 'GET_SIGN_IN_SUCCESS';
export const GET_SIGN_IN_ERROR = 'GET_SIGN_IN_ERROR';

export const GET_VERIFY_CODE_REQUEST = 'GET_VERIFY_CODE_REQUEST';
export const GET_VERIFY_CODE_SUCCESS = 'GET_VERIFY_CODE_SUCCESS';
export const GET_VERIFY_CODE_ERROR = 'GET_VERIFY_CODE_ERROR';

export const GET_NEW_CODE_REQUEST = 'GET_NEW_CODE_REQUEST';
export const GET_NEW_CODE_SUCCESS = 'GET_NEW_CODE_SUCCESS';
export const GET_NEW_CODE_ERROR = 'GET_NEW_CODE_ERROR';

export const GET_RESET_PASSWORD_REQUEST = 'GET_RESET_PASSWORD_REQUEST';
export const GET_RESET_PASSWORD_SUCCESS = 'GET_RESET_PASSWORD_SUCCESS';
export const GET_RESET_PASSWORD_ERROR = 'GET_RESET_PASSWORD_ERROR';