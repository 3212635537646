import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import CategoriesSubCtg from '../../components/CategoriesSubCtg';
import CategoriesUserCtg from '../../components/CategoriesUserCtg';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../../redux/categories/actions';
import { LinearProgress, Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {CATEGORIES_TYPE} from "../../utills/categories";
import styles from './style.module.scss';
import { getUserCategories } from '../../redux/user-categories/actions';
import CategoriesSubUserCtg from '../../components/CategoriesSubUserCtg';

function Categories() {
	const dispatch = useDispatch();
	const { loading, categories } = useSelector((state) => state.categories);
	const { user_categories } = useSelector((state) => state.user_categories);

	useEffect(() => {
		dispatch(getUserCategories());
	}, [dispatch]);

	const [selectedCategoryId, setSelectedCategoryId] = useState(null);
	const [selectedUserCategoryName, setSelectedUserCategoryName] = useState(null);
	const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);

	useEffect(() => {
		dispatch(getCategories());
	}, [dispatch]);

	const categoryHandler = (id) => {
		if (id !== selectedCategoryId) {
			setSelectedCategoryId(id);
			setSelectedSubCategoryId(null);
		}
	}

	const categoryUserHandler = (name) => {
		if (name !== selectedUserCategoryName) {
			setSelectedUserCategoryName(name);
		}
	}

	const subCategoryHandler = (id) => {
		setSelectedSubCategoryId(id);
	}

	return (
		<div>
			<Header />
			<section>
				<Aside />
				<main className="content">
					{loading && <LinearProgress color="secondary" />}
					<h1 className={styles.basicTitle}>Categories</h1>
					<div className={styles.wrapper}>
						<Box display="flex">
							<div className={styles.column}>
								<TableContainer component={Paper}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>EXPENSE</TableCell>
												<TableCell align="left">Total Count</TableCell>
												<TableCell align="left">Total Other</TableCell>
												<TableCell align="left">Total custom</TableCell>
												<TableCell align="left">Other, %</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{categories[CATEGORIES_TYPE.EXPENSE].map((c) => (
												<TableRow
													key={c.category_id}
													selected={c.category_id===selectedCategoryId}
													onClick={() => categoryHandler(c.category_id)}
												>
													<TableCell align="left">{c.category_name}</TableCell>
													<TableCell align="left">{c.total_transactions_count + c.total_sub_transactions_count}</TableCell>
													<TableCell align="left">{c.other_count}</TableCell>
													<TableCell align="left">{c.user_count}</TableCell>
													<TableCell align="left">{Math.round((c.other_count / (c.total_transactions_count + c.total_sub_transactions_count)) * 10000) / 100 }%</TableCell>
												</TableRow>
											))}
											<TableRow>
												<TableCell colSpan={5} className={styles.divider} />
											</TableRow>
											<TableRow>
											<TableCell>INCOME</TableCell>
												<TableCell colSpan={4} className={styles.divider} />
											</TableRow>
											{categories[CATEGORIES_TYPE.INCOME].map((c) => (
												<TableRow
													key={c.category_id}
													selected={c.category_id===selectedCategoryId}
													onClick={() => categoryHandler(c.category_id)}
												>
													<TableCell align="left">{c.category_name}</TableCell>
													<TableCell align="left">{c.total_transactions_count + c.total_sub_transactions_count}</TableCell>
													<TableCell align="left">{c.other_count}</TableCell>
													<TableCell align="left">{c.user_count}</TableCell>
													<TableCell align="left">{c.total_transactions_count + c.total_sub_transactions_count !=0 ? Math.round((c.other_count / (c.total_transactions_count + c.total_sub_transactions_count)) * 10000) / 100 : 0 }%</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
							<div className={styles.column}>
								{selectedCategoryId ? (
									<CategoriesSubCtg
										category={categories[CATEGORIES_TYPE.EXPENSE].concat(categories[CATEGORIES_TYPE.INCOME]).find(category => category.category_id === selectedCategoryId)}
										categories={categories[CATEGORIES_TYPE.EXPENSE].concat(categories[CATEGORIES_TYPE.INCOME]).map(category => {
											return {
												value: category.category_id,
												name: category.category_name,
											}
										})}
										categoryId={selectedCategoryId}
										subCategoryId={selectedSubCategoryId}
										categoryHandler={subCategoryHandler}
									/>
								) : (
									<Paper className="fullWidth">
										<Box display="flex" alignItems="center" justifyContent="center" className="fullHeight">
											Select a category to review
										</Box>
									</Paper>
								)}
							</div>
							<div className={styles.column}>
								{selectedSubCategoryId ? (
									<CategoriesUserCtg
										categoryId={selectedCategoryId}
										subCategoryId={selectedSubCategoryId}
									/>
								) : (
									<Paper className="fullWidth">
										<Box display="flex" alignItems="center" justifyContent="center" className="fullHeight">
											Select a subcategory to review
										</Box>
									</Paper>
								)}
							</div>
						</Box>
						<h2 className={styles.basicTitle}>User Categories</h2>
						<Box display="flex">
							<div className={styles.column}>
								<TableContainer component={Paper}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>EXPENSE</TableCell>
												<TableCell align="left">Total Count</TableCell>
												{/* <TableCell align="left">Total Other</TableCell>
												<TableCell align="left">Total custom subcategories</TableCell>
												<TableCell align="left">Other percentage</TableCell> */}
											</TableRow>
										</TableHead>
										<TableBody>
											{user_categories[CATEGORIES_TYPE.EXPENSE].map((c) => (
												<TableRow
													key={c.name}
													selected={c.name===selectedUserCategoryName}
													onClick={() => categoryUserHandler(c.name)}
												>
													<TableCell align="left">{c.name}</TableCell>
													<TableCell align="left">{c.total_transactions_count}</TableCell>
												</TableRow>
											))}
											<TableRow>
												<TableCell colSpan={5} className={styles.divider} />
											</TableRow>
											<TableRow>
											<TableCell>INCOME</TableCell>
												<TableCell colSpan={4} className={styles.divider} />
											</TableRow>
											{user_categories[CATEGORIES_TYPE.INCOME].map((c) => (
												<TableRow
													key={c.name}
													selected={c.name===selectedUserCategoryName}
													onClick={() => categoryUserHandler(c.name)}
												>
													<TableCell align="left">{c.name}</TableCell>
													<TableCell align="left">{c.total_transactions_count}</TableCell>
						
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
							<div className={styles.column}>
								{selectedCategoryId ? (
									<CategoriesSubCtg
										categoryId={selectedCategoryId}
										subCategoryId={selectedSubCategoryId}
										categoryHandler={subCategoryHandler}
									/>
								) : (
									<Paper className="fullWidth">
										<Box display="flex" alignItems="center" justifyContent="center" className="fullHeight">
											Select a category to review
										</Box>
									</Paper>
								)}
							</div>
							<div className={styles.column}>
								{selectedUserCategoryName ? (
									<CategoriesSubUserCtg
										category={user_categories[CATEGORIES_TYPE.EXPENSE].concat(user_categories[CATEGORIES_TYPE.INCOME]).find(category => category.name === selectedUserCategoryName)}
										categories={categories[CATEGORIES_TYPE.EXPENSE].concat(categories[CATEGORIES_TYPE.INCOME]).map(category => {
											return {
												value: category.category_id,
												name: category.category_name,
											}
										})}
										name={selectedUserCategoryName}
									/>
								) : (
									<Paper className="fullWidth">
										<Box display="flex" alignItems="center" justifyContent="center" className="fullHeight">
											Select a subcategory to review
										</Box>
									</Paper>
								)}
							</div>
							<div className={styles.column}>
								{selectedSubCategoryId ? (
									<CategoriesUserCtg
										categoryId={selectedCategoryId}
										subCategoryId={selectedSubCategoryId}
									/>
								) : (
									<Paper className="fullWidth">
										<Box display="flex" alignItems="center" justifyContent="center" className="fullHeight">
											Select a subcategory to review
										</Box>
									</Paper>
								)}
							</div>
						</Box>
					</div>
				</main>
				
			</section>
		</div>
	);
}

export default Categories;
