import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

function CategoriesUserCtgItem({
    category,
    dropDownOptions,
    handleSetGroup
}) {
    const FIELD_NAME = 'name';

    const defaultValue = dropDownOptions.find(({ id }) => category.user_category_group_id === id);

    const [value, setValue] = useState(defaultValue);

    const handleKeyPress = (evt) => {
        if (evt.key === 'Enter') {
            const isNewValue = !dropDownOptions.some((option) => option[FIELD_NAME] === value[FIELD_NAME]);
            if (isNewValue) {
                handleSetGroup(value, category.user_category_id);
            }
        }
    }

    return (
        <TableRow>
            <TableCell align="left">{category.total_transactions_count}</TableCell>
            <TableCell align="left">{category.user_category_name}</TableCell>
            <TableCell align="left">
                <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue({
                                [FIELD_NAME]: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                [FIELD_NAME]: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                        handleSetGroup(newValue?.inputValue || newValue, category.user_category_id);
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                [FIELD_NAME]: `Add "${params.inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={dropDownOptions}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            handleSetGroup({
                                [FIELD_NAME]: option.inputValue,
                            }, category.user_category_id);
                            return option.inputValue;
                        }
                        // Regular option
                        return option[FIELD_NAME];
                    }}
                    renderOption={(option) => option[FIELD_NAME]}
                    style={{ minWidth: 150 }}
                    freeSolo
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            onKeyPress={handleKeyPress}
                            size="small"
                        />
                    )}
                />
            </TableCell>
        </TableRow>
    )
}

CategoriesUserCtgItem.propTypes = {
    category: PropTypes.object.isRequired,
    dropDownOptions: PropTypes.array,
    handleSetGroup: PropTypes.func.isRequired,
}

export default CategoriesUserCtgItem;
