import api from '../../utills/api';
import {
	GET_ALL_BANK_LIST,
	GET_ALL_BANK_SUCCESS,
	GET_ALL_BANK_ERROR,
} from './constants';

export const getAllBankList = () => ({
	type: GET_ALL_BANK_LIST
});

const getAllBankSuccess = (payload) => ({
	type: GET_ALL_BANK_SUCCESS,
	payload
});

const getAllBankError = (payload) => ({
	type: GET_ALL_BANK_ERROR,
	payload
});

// const getAllBankUpdateRequest = (payload) => ({
// 	type: GET_ALL_BANK_ERROR,
// 	payload
// });

export const getBanks = () => (dispatch) => {
	dispatch(getAllBankList());

	api.banks
		.getBanks()
		.then((data) => {
			if (!data.error) {
				console.log(data);
				dispatch(getAllBankSuccess(data));
			} else {
				dispatch(getAllBankError(data));
			}
		})
		.catch((err) => {
			dispatch(getAllBankError(err))
		});
};

export const putBanks = (body) => (dispatch) => {
	dispatch(getAllBankList());
	console.log("test",body);
	api.settings
		.putBanks(body)
		.then((data) => {
			if (!data.error) {
				console.log(data);
				dispatch(getAllBankSuccess(data));
			} else {
				//dispatch(getAllBankError(data));
			}
		})
		.catch((err) => {
			console.log(err);
			//dispatch(getAllBankError(err))
		});
};