import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import { LinearProgress, Box, Checkbox, FormControlLabel } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getBanks } from '../../redux/banks/actions';
import api from '../../utills/api';
import { Link, useHistory } from 'react-router-dom';

function Banks() {
	const dispatch = useDispatch();
	const pathHistory = useHistory();

	let { loading, banks } = useSelector((state) => state.banks);

	useEffect(
		() => {
			dispatch(getBanks());
		},
		[dispatch]
	);

	if(!banks){
		banks = [];
	}


	function handleChange1(event, bank_id) {
		console.log(event.target.checked);
		console.log(bank_id);

		api.banks.putBanks({
			id: bank_id,
			show: event.target.checked
		});
	}

	function handleChange2(event, bank_id) {
		api.banks.putBanks({
			id: bank_id,
			auto_update: event.target.checked
		});
	}

	function handleChange3(event, bank_id) {
		api.banks.putBanks({
			id: bank_id,
			camera_required: event.target.checked
		});
	}

	return (
		<div>
			<Header />
			<section>
				<Aside />
				<main className="content">
					{loading && <LinearProgress color="secondary" />}
					<h1 className={styles.basicTitle}>Banks</h1>
					<div className={styles.wrapper}>
						<Box display="flex">
							<div>
								<TableContainer component={Paper}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>BANKS</TableCell>
												<TableCell align="left">Name</TableCell>
												<TableCell align="left">Type</TableCell>
												<TableCell align="left">Show</TableCell>
												<TableCell align="left">Auto Refresh</TableCell>
												<TableCell align="left">Camera required</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{banks.map((b) => (
												<TableRow key={b.id}>
													<TableCell align="left"> <img
														src={`${b.logo_url}?w=14&fit=crop&auto=format`}
														height={64}
														loading="lazy"
													/></TableCell>
													<TableCell align="left">
														<Link component="button" onClick={
															() => {
																pathHistory.push({
																	pathname:"/history",
																	search: `?bank=${b.name}&bankId=${b.id}`,
																})
															}
														}>
															{b.name}
														</Link>
													</TableCell>
													<TableCell align="left">{b.type}</TableCell>
													<TableCell align="left"><FormControlLabel control={<Checkbox defaultChecked={b.show} onChange={ (event) => {
														handleChange1(event, b.id)
													}} />}/></TableCell>
													<TableCell align="left"><FormControlLabel control={<Checkbox defaultChecked={b.auto_update} onChange={ (event) => {
														handleChange2(event, b.id)
													}}/>}/></TableCell>
													<TableCell align="left"><FormControlLabel control={<Checkbox defaultChecked={b.camera_required} onChange={ (event) => {
														handleChange3(event, b.id)
													}}/>}/></TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						</Box>
					</div>
				</main>
				
			</section>
		</div>
	);
}

export default Banks;
