import api from "../../utills/api.js";
import {
    GET_BANK_STATISTICS,
    GET_BANK_STATISTICS_SUCCESS,
    GET_BANK_STATISTICS_ERROR,
} from "./constants.js"

export const getBankStatistics = () => ({
    type: GET_BANK_STATISTICS
});

const getBankStatisticsSuccess = (payload) => ({
    type: GET_BANK_STATISTICS_SUCCESS,
    payload
});

const getBankStatisticsError = (payload) => ({
    type: GET_BANK_STATISTICS_ERROR,
    payload
});

export const fetchBankStatistics = () => (dispatch) => {
    dispatch(getBankStatistics());

    api.banks.getStatistics()
        .then(data=>{
            if(!data.error){
                data.sort((a, b) => {
                    if (JSON.parse(a.totalNumberConnection) > JSON.parse(b.totalNumberConnection)) {
                        return -1;
                    }
                    if (JSON.parse(a.totalNumberConnection) < JSON.parse(b.totalNumberConnection)) {
                        return 1;
                    }
                    return 0;
                });
                dispatch(getBankStatisticsSuccess(data))
            } else {
                dispatch(getBankStatisticsError({error: data}))
            }
        })
        .catch(err=>getBankStatisticsError(err))
}
