import { toast } from 'react-toastify';
import api from '../../utills/api';
import {
	GET_ADMINS_REQUEST,
	GET_ADMINS_SUCCESS,
	GET_ADMINS_ERROR,
	DELETE_ADMIN_REQUEST,
	DELETE_ADMIN_SUCCESS,
	DELETE_ADMIN_ERROR,
	EDIT_ADMIN_REQUEST,
	EDIT_ADMIN_SUCCESS,
	EDIT_ADMIN_ERROR,
	APPROVE_ADMIN_REQUEST,
	APPROVE_ADMIN_SUCCESS,
	APPROVE_ADMIN_ERROR,
	GET_PENDING_ADMINS_REQUEST,
	GET_PENDING_ADMINS_SUCCESS,
	GET_PENDING_ADMINS_ERROR
} from './constants';

const getAdminsRequest = () => ({
	type: GET_ADMINS_REQUEST
});

const getAdminsError = (payload) => ({
	type: GET_ADMINS_ERROR,
	payload
});

const getAdminsSuccess = (payload) => ({
	type: GET_ADMINS_SUCCESS,
	payload
});

export const getAdmins = (page=1) => (dispatch) => {
	dispatch(getAdminsRequest());
	api.admins
		.getAdmins(page)
		.then((data) => {
			if (!data.error) {
				dispatch(getAdminsSuccess(data));
			} else {
				toast.error('Something Went Wrong');
				dispatch(getAdminsError(data));
			}
		})
		.catch((err) => {
			toast.error('Something Went Wrong');
			dispatch(getAdminsError(err))});
};
const deleteAdminRequest = () => ({
	type: DELETE_ADMIN_REQUEST
});

const deleteAdminError = (payload) => ({
	type: DELETE_ADMIN_ERROR,
	payload
});

const deleteAdminSuccess = (payload) => ({
	type: DELETE_ADMIN_SUCCESS,
	payload
});

export const deleteAdmin = (id, successCb) => (dispatch) => {
	dispatch(deleteAdminRequest());
	api.admins
		.deleteAdmin(id)
		.then((data) => {
			if (!data.error) {
				dispatch(deleteAdminSuccess(data));
				toast.success('Success');
				successCb();
			} else {
				toast.error('Something Went Wrong');
				dispatch(deleteAdminError(data));
			}
		})
		.catch((err) => {
			toast.error('Something Went Wrong');
			dispatch(deleteAdminError(err))});
};

const editAdminRequest = (id) => ({
	type: EDIT_ADMIN_REQUEST,
	id
});

const editAdminError = (payload) => ({
	type: EDIT_ADMIN_ERROR,
	payload
});

const editAdminSuccess = (payload) => ({
	type: EDIT_ADMIN_SUCCESS,
	payload
});

export const editAdmin = (id, body, successCb) => (dispatch) => {
	dispatch(editAdminRequest());
	api.admins
		.editAdmin(id, body)
		.then((data) => {
			if (!data.error) {
				dispatch(editAdminSuccess(data));
				toast.success('Success');
				successCb();
			} else {
				toast.error('Something Went Wrong');
				dispatch(editAdminError(data));
			}
		})
		.catch((err) => {
			toast.error('Something Went Wrong');
			dispatch(editAdminError(err))});
};
const approveAdminRequest = (id) => ({
	type: APPROVE_ADMIN_REQUEST,
	id
});

const approveAdminError = (payload) => ({
	type: APPROVE_ADMIN_ERROR,
	payload
});

const approveAdminSuccess = (payload) => ({
	type: APPROVE_ADMIN_SUCCESS,
	payload
});

export const approveAdmin = (id, body, successCb) => (dispatch) => {
	dispatch(approveAdminRequest());
	api.admins
		.approveAdmin(id, body)
		.then((data) => {
			if (!data.error) {
				dispatch(approveAdminSuccess(data));
				toast.success('Success');
				successCb();
			} else {
				toast.error('Something Went Wrong');
				dispatch(approveAdminError(data));
			}
		})
		.catch((err) => {
			toast.error('Something Went Wrong');
			dispatch(approveAdminError(err))});
};

const getPendingAdminsRequest = () => ({
	type: GET_PENDING_ADMINS_REQUEST
});

const getPendingAdminsError = (payload) => ({
	type: GET_PENDING_ADMINS_ERROR,
	payload
});

const getPendingAdminsSuccess = (payload) => ({
	type: GET_PENDING_ADMINS_SUCCESS,
	payload
});

export const getPendingAdmins =
	() =>
	(dispatch) => {
		dispatch(getPendingAdminsRequest());
		api.admins
			.getPendingAdmins()
			.then((data) => {
				if (!data.error) {
					dispatch(getPendingAdminsSuccess(data));
				} else {
					dispatch(getPendingAdminsError(data));
				}
			})
			.catch((err) => {
				dispatch(getPendingAdminsError(err));
			});
	};