export const ROLE = {
	admin: 'ADMIN',
	super: 'SUPER_ADMIN'
};
export const setUserTokenToStorage = (data) => {
	console.log("REFRRESH TOKEN");
	console.log(data);

	const payload = JSON.parse(atob(data.access_token.split('.')[1]));
	localStorage.setItem('access_token', data.access_token);
	localStorage.setItem('expire', payload.exp * 1000);
	localStorage.setItem('refresh_token', data.refresh_token);
	localStorage.setItem('role', data.role);
	data.is_verified && localStorage.setItem('is_verified', data.is_verified);
};

export const removeUserTokenToStorage = () => {
	localStorage.removeItem('access_token');
	localStorage.removeItem('expire');
	localStorage.removeItem('refresh_token');
	localStorage.removeItem('is_verified');
};

export const getUserTokenFromStorage = () => {
	const refresh_token = localStorage.getItem('refresh_token');
	const access_token = localStorage.getItem('access_token');
	const expire = localStorage.getItem('expire');
	const is_verified = localStorage.getItem('is_verified');
	const role = localStorage.getItem('role');
	return {
		refresh_token,
		access_token,
		expire,
		is_verified,
		role
	};
};
export const getAccesstoken = () => localStorage.getItem('access_token');
