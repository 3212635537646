import { combineReducers } from 'redux';
import user from './user/reducer';
import admins from './admins/reducer';
import dashboard from './dashboard/reducer';
import categories from './categories/reducer';
import user_categories from './user-categories/reducer';
import settings from './settings/reducer';
import banks from './banks/reducer';
import history from './history/reducer';
import banksStatistics from './banksStatistics/reducer';

export default combineReducers({
	user,
	admins,
	dashboard,
	categories,
	user_categories,
	settings,
	banks,
	history,
	banksStatistics
});
