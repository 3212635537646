import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import styles from './style.module.scss';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@material-ui/core';
import { ROLE } from '../../utills/user';

export default function EditAdminForm({ admin, editAdminSubmitHandler, handleClose }) {
	const { register, handleSubmit, setValue } = useForm();

	const [role, setRole] = React.useState(ROLE.admin);

	const onSubmit = (data) => {
    editAdminSubmitHandler(admin.id,{
			...data,
			user_role:role
		});
	};

	const userRoleHandler = (e) => {
		const { value } = e.target;
		setRole(value);
	};

	useEffect(() => {
		setValue('email', admin.email);
		setValue('is_approved', admin.is_approved);
		setValue('is_blocked', admin.is_blocked);
		setValue('is_verified', admin.is_verified);
		setValue('is_mfa_passed', admin.is_mfa_passed);
		setRole(admin.user_role);
	}, [admin]);

	return (
		<div className={styles.wrapper}>
			<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
				<div className={styles.row}>
					<label className={styles.label}>Email:</label>
					<input  placeholder="Enter your mail" className={styles.input} {...register('email', { required: true })} />
				</div>
				<div className={styles.row}>
					<label className={styles.label}>Role: </label>
					<Select
						className={styles.select}
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={role}
						onChange={userRoleHandler}
					>
						<MenuItem value={ROLE.admin}>Admin</MenuItem>
						<MenuItem value={ROLE.super}>Super Admin</MenuItem>
					</Select>
				</div>
				<div className={styles.row}>
					<label className={styles.label}>Blocked: </label>
					<input type="checkbox" {...register('is_blocked')} />
				</div>
				<div className={styles.row}>
					<label className={styles.label}>Verified: </label>
					<input type="checkbox" {...register('is_verified')} />
				</div>
				<div className={styles.row}>
					<label className={styles.label}>MFA Passed: </label>
					<input type="checkbox" {...register('is_mfa_passed')} />
				</div>
				<div className={styles.submitWrapper}>
					<Button variant="contained" color="secondary" onClick={handleClose}>
						Cancel
					</Button>
					{' '}
					<Button variant="contained" color="primary" type="submit" className={styles.submit}>
						Save
					</Button>
				</div>
			</form>
		</div>
	);
}

EditAdminForm.propTypes = {
	admin: PropTypes.object,
	editAdminSubmitHandler: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
};
