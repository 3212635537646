import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Admins from './Admins';
import Users from './Users';
import Settings from './Settings';
import Categories from './Categories';
import UserCategories from './UserCategories';
import Banks from './Banks';
import Home from './Home';
import BanksStatistics from './BanksStatistics';
import Login from './Login';
import '../styles/reset.scss'
import '../styles/global.scss'
import ProtectedRoute from '../components/ProtectedRoute';
import { PATH } from '../utills/Path';
import NotFound from './NotFound';
import ResetPassword from './ResetPassword';
import Signup from './Signup';
import PublicRoute from '../components/PublicRoute';
import CodeVerification from './CodeVerification';
import Statistics from './Statistics';
import History from './History';

export default function App() {
	return (
		<>
			<ToastContainer />
			<Router>
				<Switch>
					<ProtectedRoute exact path={PATH.admins} component={Admins} />
					<ProtectedRoute exact path={PATH.users} component={Users} />
					<ProtectedRoute exact path={PATH.statistics} component={Statistics} />
					<ProtectedRoute exact path={PATH.settings} component={Settings} />
					<ProtectedRoute exact path={PATH.categories} component={Categories} />
					<ProtectedRoute exact path={PATH.usercategories} component={UserCategories} />
					<ProtectedRoute exact path={PATH.banks} component={Banks} />
					<ProtectedRoute exact path={PATH.banksStatistics} component={BanksStatistics} />
					<ProtectedRoute exact path={PATH.history} component={History} />
					<PublicRoute path={PATH.signin} component={Login} />
					<PublicRoute path={PATH.codeVerification} component={CodeVerification} />
					<PublicRoute path={PATH.resetPassword} component={ResetPassword} />
					<PublicRoute path={PATH.signup} component={Signup} />
					<ProtectedRoute exact path={PATH.home} component={Home} />
					<PublicRoute path={PATH.all} component={NotFound} />
				</Switch>
			</Router>
		</>
	);
}