import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './index';
import logger from 'redux-logger';


const enhacer = applyMiddleware(logger, thunk);

const store = createStore(rootReducer, enhacer);

export default store;
