import {
    GET_BANK_STATISTICS,
    GET_BANK_STATISTICS_SUCCESS,
    GET_BANK_STATISTICS_ERROR
} from "./constants.js"

const initState = {
    loading: false,
    statistics: [],
    error: null
}
    
export default (state = initState, action) => {
    const {
        type,
        payload
    } = action;
    switch (type) {
        case GET_BANK_STATISTICS:
            return {
                ...state,
                loading: true
            };

        case GET_BANK_STATISTICS_SUCCESS:
            return {
                ...state,
                loading: false,
                statistics: payload
            };

        case GET_BANK_STATISTICS_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };

        default:
            return {
                ...state
            };
    }
}
