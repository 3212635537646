import fetchIntercept from 'fetch-intercept';
import {
	getAccesstoken
} from './user';
import auth from './auth';

// eslint-disable-next-line no-undef
export const BASE_URL = process.env.REACT_APP_ENV_API_URL;

fetchIntercept.register({
	request: async (url, config) => {
		await auth.checkToken(url);

		if (!url.includes('refresh-token')) {
			config.headers.Authorization = `Bearer ${getAccesstoken()}`;
		}
		return [url, config];
	},

	requestError: function (error) {
		return Promise.reject(error);
	},

	response: function (response) {
		return response;
	},

	responseError: function (error) {
		return Promise.reject(error);
	}
});

const api = {
	user: {
		signup: (body) =>
			fetch(`${BASE_URL}/signup`, {
				method: 'POST',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then((res) => res.json())
				.catch((err) => err),
		resetPassword: (body) =>
			fetch(`${BASE_URL}/password-reset`, {
				method: 'POST',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then((res) => res)
				.catch((err) => err),
		refresh: async (token) =>
			await fetch(`${BASE_URL}/refresh-token/${token}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
			}),
		signin: (body) =>
			fetch(`${BASE_URL}/signin`, {
				method: 'POST',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then((res) => res.json())
				.catch((err) => err),
		verifyCode: (body) =>
			fetch(`${BASE_URL}/verify`, {
				method: 'POST',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then((res) => res.json())
				.catch((err) => err),
		getNewCode: () =>
			fetch(`${BASE_URL}/verification-code`, {
				method: 'POST',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err)
	},
	admins: {
		getAdmins: (page) =>
			fetch(`${BASE_URL}/admins?limit=10&page=${page}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getPendingAdmins: () =>
			fetch(`${BASE_URL}/admins?approved=fals&limit=100&page=1`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		deleteAdmin: (id) =>
			fetch(`${BASE_URL}/admins/${id}`, {
				method: 'DELETE',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		editAdmin: (id, body) =>
			fetch(`${BASE_URL}/admins/${id}`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then((res) => res.json())
				.catch((err) => err),
		approveAdmin: (id, body) =>
			fetch(`${BASE_URL}/admins/set-approved/${id}`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then((res) => res.json())
				.catch((err) => err)
	},
	dashboard: {
		getConnectionCountByStatus: (status) =>
			fetch(`${BASE_URL}/dashboard/connections-count-by-status?status=${status}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getTransactionCount: () =>
			fetch(`${BASE_URL}/dashboard/transactions-count`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getTotalConnectionsCount: () =>
			fetch(`${BASE_URL}/dashboard/total-connections-count`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getTotalUserStatistics: () =>
			fetch(`${BASE_URL}/dashboard/users-statistics`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getTotalAccountsCount: () =>
			fetch(`${BASE_URL}/dashboard/total-accounts-count`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getConnections: (update) => {
			return fetch(`${BASE_URL}/dashboard/connections?update=${update}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err)
		},
		getConnectionsExcel: () => {
			return fetch(`${BASE_URL}/dashboard/connection-excel`, {
				headers: {},
				method: 'GET'
			})
				.then((res) => {
					console.log(res);
					return res.blob();
				})
				.catch((err) => { console.log(err)})
		},
		syncLeanDataRawDownload: (id) => {
			return fetch(`${BASE_URL}/dashboard/sync-lean-raw-data?connectionSyncId=${id}`, {
				headers: {},
				method: 'GET'
			})
				.then((res) => {
					console.log(res);
					return res.blob();
				})
				.catch((err) => { console.log(err)})
		}
	},
	categories: {
		getCategories: ({
			type,
			page,
			limit
		} = {
				type: 'EXPENSE',
				page: 1,
				limit: 10
			}) =>
			fetch(`${BASE_URL}/categories/list?show_only_parent_categories=true&type=${type}&page=${page}&limit=${limit}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getSubCategories: (id) =>
			fetch(`${BASE_URL}/categories/list/subcategories/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getUserCategories: (id) =>
			fetch(`${BASE_URL}/categories/list/user-categories/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getUserCategoriesByName: (name) =>
			fetch(`${BASE_URL}/categories/list/user-categories/name/${name}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getGroups: (id) =>
			fetch(`${BASE_URL}/categories/groups/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		addGroup: (body) =>
			fetch(`${BASE_URL}/categories/addGroup`, {
				method: 'POST',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then((res) => res)
				.catch((err) => err),
		changeCategory: (body) =>
			fetch(`${BASE_URL}/categories/change-parent`, {
				method: 'POST',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then((res) => res)
				.catch((err) => err),

	},
	user_categories: {
		getCategories: ({
			type,
			page,
			limit
		} = {
				type: 'EXPENSE',
				page: 1,
				limit: 10
			}) =>
			fetch(`${BASE_URL}/categories/list/user-categories?show_only_parent_categories=true&type=${type}&page=${page}&limit=${limit}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getSubCategories: (id) =>
			fetch(`${BASE_URL}/categories/list/subcategories/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getUserCategories: (id) =>
			fetch(`${BASE_URL}/categories/list/user-categories/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		getGroups: (id) =>
			fetch(`${BASE_URL}/categories/groups/${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => res.json())
				.catch((err) => err),
		addGroup: (body) =>
			fetch(`${BASE_URL}/categories/addGroup`, {
				method: 'POST',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then((res) => res)
				.catch((err) => err),

	},
	settings: {
		getSettings: () =>
			fetch(`${BASE_URL}/dashboard/settings`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
			})
				.then((res) => res.json())
				.catch((err) => err),
		putSettings: (body) =>
			fetch(`${BASE_URL}/dashboard/settings`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then((res) => res.json())
				.catch((err) => err),
	},
	banks: {
		getBanks: () =>
			fetch(`${BASE_URL}/dashboard/banks`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
			})
				.then((res) => res.json())
				.catch((err) => err),
		putBanks: (body) =>
			fetch(`${BASE_URL}/dashboard/banks`, {
				method: 'PUT',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			})
				.then((res) => res.json())
				.catch((err) => err),
		getStatistics: () =>
			fetch(`${BASE_URL}/dashboard/statistics-of-banks`,{
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
			})
				.then((res) => res.json())
				.catch((err) => err),
	},
	history:{
		getConnectionHistory: (id) =>
			fetch(`${BASE_URL}/dashboard/connections-histories?connectionId=${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
			})
			.then((res) => res.json())
			.catch((err) => err),
		getBankHistory: (id) =>
			fetch(`${BASE_URL}/dashboard/connections-histories?bankId=${id}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				},
			})
			.then((res) => res.json())
			.catch((err) => err),
	},
	users: {
		getAppVersions: () => {
			return fetch(`${BASE_URL}/dashboard/users-app-versions`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
			.then((res) => {
				return res.json()
			})
			.catch((err) => {throw err})
		},
		getUsers: (update) => {
			return fetch(`${BASE_URL}/dashboard/users?update=${update}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
			})
				.then((res) => {
					return res.json()
				})
				.catch((err) => err)
		},
		getUsersExcel: () => {
			return fetch(`${BASE_URL}/dashboard/user-excel`, {
				headers: {},
				method: 'GET'
			})
				.then((res) => {
					console.log(res);
					return res.blob();
				})
				.catch((err) => { console.log(err)})
		}
	}
};

export default api;