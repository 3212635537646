import api from '../../utills/api';
import { toast } from 'react-toastify';
import {
	GET_SIGN_UP_REQUEST,
	GET_SIGN_UP_SUCCESS,
	GET_SIGN_UP_ERROR,
	CLEAR_USER_ERROR,
	GET_SIGN_IN_REQUEST,
	GET_SIGN_IN_SUCCESS,
	GET_SIGN_IN_ERROR,
	GET_VERIFY_CODE_REQUEST,
	GET_VERIFY_CODE_SUCCESS,
	GET_VERIFY_CODE_ERROR,
	GET_NEW_CODE_REQUEST,
	GET_NEW_CODE_SUCCESS,
	GET_NEW_CODE_ERROR,
	GET_RESET_PASSWORD_REQUEST,
	GET_RESET_PASSWORD_SUCCESS,
	GET_RESET_PASSWORD_ERROR
} from './constants';
import { setUserTokenToStorage } from '../../utills/user';

export const clearUserError = () => ({
	type: CLEAR_USER_ERROR
});

const getSignUpRequest = () => ({
	type: GET_SIGN_UP_REQUEST
});

const getSignUpError = (payload) => ({
	type: GET_SIGN_UP_ERROR,
	payload
});

const getSignUpSuccess = (payload) => ({
	type: GET_SIGN_UP_SUCCESS,
	payload
});

export const register = (body, successCb) => (dispatch) => {
	dispatch(getSignUpRequest());
	api.user
		.signup(body)
		.then((data) => {
			if (!data.error) {
				setUserTokenToStorage(data);
				dispatch(getSignUpSuccess(data));
				toast.success('Success');
				successCb();
			}else{
				dispatch(getSignUpError(data));
				toast.error('Something Went Wrong');
			}
		})
		.catch((err) => {
			toast.error('Something Went Wrong');
			dispatch(getSignUpError(err))});
};

const getSignInRequest = () => ({
	type: GET_SIGN_IN_REQUEST
});

const getSignInError = (payload) => ({
	type: GET_SIGN_IN_ERROR,
	payload
});

const getSignInSuccess = (payload) => ({
	type: GET_SIGN_IN_SUCCESS,
	payload
});


export const login = (body, successCb) => (dispatch) => {
	dispatch(getSignInRequest());
	api.user
		.signin(body)
		.then((data) => {
			if (!data.error) {
				setUserTokenToStorage(data);
				dispatch(getSignInSuccess(data));
				successCb(data);
			} else {
				dispatch(getSignInError(data));
			}
		})
		.catch((err) => {
			dispatch(getSignInError(err))});
};


const verifyCodeRequest = () => ({
	type: GET_VERIFY_CODE_REQUEST
});

const verifyCodeError = (payload) => ({
	type: GET_VERIFY_CODE_ERROR,
	payload
});

const verifyCodeSuccess = (payload) => ({
	type: GET_VERIFY_CODE_SUCCESS,
	payload
});

export const verifyCode = (body, successCb) => (dispatch) => {
	dispatch(verifyCodeRequest());
	api.user
		.verifyCode(body)
		.then((data) => {
			if (!data.error) {
				dispatch(verifyCodeSuccess(data));
				toast.success('Success');
				successCb();
			} else {
				dispatch(verifyCodeError(data));
				toast.error('Something Went Wrong');
			}
		})
		.catch((err) => {
			toast.error('Something Went Wrong');
			dispatch(verifyCodeError(err))});
};

const getNewCodeRequest = () => ({
	type: GET_NEW_CODE_REQUEST
});

const geteNewCodeError = (payload) => ({
	type: GET_NEW_CODE_ERROR,
	payload
});

const getNewCodeSuccess = (payload) => ({
	type: GET_NEW_CODE_SUCCESS,
	payload
});

export const getNewCode = () => (dispatch) => {
	dispatch(getNewCodeRequest());
	api.user.getNewCode()	
		.then((data) => {
			if (!data.error) {
				dispatch(getNewCodeSuccess(data));
				toast.success('Success');
			} else {
				dispatch(geteNewCodeError(data));
				toast.error('Something Went Wrong');
			}
		})
		.catch((err) => {toast.error('Something Went Wrong');
		dispatch(geteNewCodeError(err))});
};
const getResetPasswordRequest = () => ({
	type: GET_RESET_PASSWORD_REQUEST
});

const geteResetPasswordError = (payload) => ({
	type: GET_RESET_PASSWORD_ERROR,
	payload
});

const getResetPasswordSuccess = (payload) => ({
	type: GET_RESET_PASSWORD_SUCCESS,
	payload
});

export const resetPassword = (body) => (dispatch) => {
	dispatch(getResetPasswordRequest());
	api.user
		.resetPassword(body)
		.then((data) => {
			if (!data.error) {
				dispatch(getResetPasswordSuccess(data));
				toast.success('Success');
			} else {
				dispatch(geteResetPasswordError(data));
				toast.error('Something Went Wrong');
			}
		})
		.catch((err) => {
			toast.error('Something Went Wrong');
			dispatch(geteResetPasswordError(err));
		});
};
