import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import { useDispatch, useSelector } from 'react-redux';
import { getConnections, getTotalUserStatistics } from '../../redux/dashboard/actions';
import { Box, Checkbox, FormControlLabel, Grid, LinearProgress, Link, TextField } from '@material-ui/core';
import styles from './style.module.scss';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PaginationComponent from '../../components/Pagination/indedx';
import { ORDER } from '../../enums/orderByEnums';
import { roundPercentage } from '../../utills/dashboard';
import { getBanks } from '../../redux/banks/actions';
import { MultiSelect } from 'react-multi-select-component';
import { useHistory } from 'react-router';
import Popup from '../../components/Popup';
import api from '../../utills/api';

function Home() {
	const history = useHistory()
	const dispatch = useDispatch();
	
	const dashboard = useSelector((state) => state.dashboard);
	let { banks } = useSelector((state) => state.banks);
	let {
		loading,
		loadingConnection,
		connections,
	} = useSelector((state) => state.dashboard);

	const downloadExcel = () => {
		api.dashboard.getConnectionsExcel().catch((err) => {
			console.log(err);
		}).then(res => {
			const aElement = document.createElement('a');
			aElement.setAttribute('download', 'connectionList.xlsx');
			const href = URL.createObjectURL(res);
			aElement.href = href;
			aElement.setAttribute('target', '_blank');
			aElement.click();
			URL.revokeObjectURL(href);
		});
	}

	const [bankSelected, setBankSelected] = useState([]);
	const [subscribeSelected, setSubscribeSelected] = useState([]);
	const [data, setData] = useState([]);
	const [filteredConnections, setFilteredConnections] = useState([]);
	const [rows, setRows] = useState([]);
		
	
	const [search, setSearch] = useState(null);
	
	const [popup,setPopup] = useState({
		show: false,
		error: "",
		information_action_message: "",
		information_mobile_message: "",
		information_service_message: "",
	});

	const [stateFilter, setFilter] = useState({
		status: null,
		search: null,
		limit: 50,
		bank: [],
		subscribe: [],
		accountCount: null,
		transactionCount: null,
		connection: null,
		lastTransaction: null,
		lastAutomaticSuccess: null,
		lastManualSuccess: null,
		lastAutomaticFail: null,
		lastManualFail: null,
		created: null,
		lastSync: null,
		type: null,
		withPlus: false,
		changed: false,
	});

	const [counters, setCounters] = useState({
		subscriptions: {
			subscribed: 0,
			unsubscribed: 0,
			new: 0,
			trail: 0,
		},
		types: {
			leantech: 0,
			saltedge: 0,
		},
		banks: {
			...banks?.reduce((banks, bank) => {
				banks[bank.name] = 0;
				return banks;
			}, {}),
		},
	});

	const [pagination, setPagination] = useState({
		page: 1,
		pageCount: 1,
	});

	const [sortCriteria, setSortCriteria] = useState({
		field: '',
		order: ORDER.DESC,
	});

	useEffect(() => {
		setData(connections);
	}, [connections]);
	
	useEffect(() => {
		handleFilter({...stateFilter, subscribe: subscribeSelected, bank: bankSelected});
	}, [bankSelected, subscribeSelected]);
	
	useEffect(() => {
		countersHandler();
	}, [data]);

	useEffect(() => {
		paginationHandler(1);
	}, [filteredConnections]);

	useEffect(() => {
		filterData();
	}, [stateFilter]);


	useEffect(() => {
		dispatch(getTotalUserStatistics());

		const query = new URLSearchParams(window.location.search);
		let searchParams = query.get('search')

		setSearch(searchParams);

		if (!searchParams) {
			searchParams = "";
		}

		dispatch(getConnections(1));
		dispatch(getBanks());

	}, [dispatch]);

	useEffect(() => {
		countersHandler();
	}, [banks, connections]);

	
	useEffect(() => {
		const searchParams = new URLSearchParams(history.location.search);
		const page = searchParams.get("page") || 1;
		const pageCount = Math.ceil(connections.length / stateFilter.limit);
		
		setPagination({
			...pagination,
			pageCount,
			page,
		});
		
		filterData();
		countersHandler();
	}, [connections]);
	
	const handleFilter = (filter) => {
		setFilter({
			...filter,
			changed: true,
		});
	};

	
	const paginationHandler = (page) => {
		setPagination({
			...pagination,
			pageCount: Math.ceil(filteredConnections.length / stateFilter.limit),
			page,
		});

		const searchParams = new URLSearchParams(location.search);
		searchParams.set('page', page);
		history.push({
			search: searchParams.toString(),
		});

		let paginated = [...filteredConnections].slice(
			(page - 1) * stateFilter.limit,
			page * stateFilter.limit
		);

		setRows(paginated);
	};
	
	
	
	const filterData = () => {
		if(connections) {
			let filtered = [...connections].filter((connection) => {
				let shouldReturn = false;
				if (stateFilter.subscribe?.length > 0) {
					let found = false;
					
					stateFilter.subscribe.forEach(subscription => {
						if (subscription.value === connection.subscription) {
							found = true;
						}
					});

					if (!found) {
						shouldReturn = true;
					}
				}
				
				if (stateFilter.bank?.length > 0) {
					let found = false;
					
					stateFilter.bank.forEach(bank => {
						if (bank.value === connection.bank) {
							found = true;
						}
					});
					
					if (!found) {
						shouldReturn = true;
					}
				}	
				
				if (stateFilter.type && stateFilter.type !== connection.type) {
					shouldReturn = true;
				}
				
				if (
					stateFilter.search?.length > 0 && (
					!connection.user_email?.includes(stateFilter.search)
					&& !connection.providerConnectionId?.includes(stateFilter.search)
					&& !connection.id?.toString().includes(stateFilter.search)
				)) {
					shouldReturn = true;
				}
					
				if (!stateFilter.withPlus && connection.user_email?.includes("+")) {
					shouldReturn = true;
				}

				if(shouldReturn) return;

				return connection;
			});

			setFilteredConnections(filtered);
		}
	}

	const sortData = (field) => {
		const sortedData = [...filteredConnections];

		let order = ORDER.ASC;
		
		if (sortCriteria.field === field && sortCriteria.order === ORDER.ASC) {
			order = ORDER.DESC;
		}

		sortedData.sort((a, b) => {
			if (field === 'lastTransaction') {
				const dateA = new Date(a[field]);
				const dateB = new Date(b[field]);
		
				if (dateA < dateB) {
					return order === 'ASC' ? -1 : 1;
				}

				if (dateA > dateB) {
					return order === 'ASC' ? 1 : -1;
				}

				return 0;
			} else {
				if (a[field] < b[field]) {
					return order === ORDER.ASC ? -1 : 1;
				}

				if (a[field] > b[field]) {
					return order === ORDER.ASC ? 1 : -1;
				}

				return 0;
			}
		});

		setSortCriteria({
			field,
			order,
		});

		setFilteredConnections(sortedData);
	}

	function sortDate(field) {
		const splitParams = field.split('.');
		
		setFilteredConnections([...filteredConnections].sort((a, b) => {
			if (a[splitParams[0]] && !b[splitParams[0]]) {
				return sortCriteria.order === ORDER.ASC ? 1: -1;
			}
			if (!a[splitParams[0]] && b[splitParams[0]]) {
				return sortCriteria.order === ORDER.ASC? -1: 1;
			}
			if (!a[splitParams[0]] || !b[splitParams[0]]) {
				return 0;
			}
			return sortCriteria.order === ORDER.ASC
			? new Date(a[splitParams[0]][splitParams[1]]) - new Date(b[splitParams[0]][splitParams[1]])
			: new Date(b[splitParams[0]][splitParams[1]]) - new Date(a[splitParams[0]][splitParams[1]])
		}));
		
		setSortCriteria({
			...sortCriteria,
			field,
			order: sortCriteria.order === ORDER.ASC ? ORDER.DESC : ORDER.ASC
		})
	}


	// const sortDate = (field) => {
	// 	console.log(`clicked ${field}`);
	// 	const splittedField = field.split(".");

	// 	const sortedData = [...filteredConnections].sort((a, b)=>{
	// 		if(a[splittedField[0]] && !b[splittedField[0]]){
	// 			return -1
	// 		}

	// 		if(!a[splittedField[0]] && b[splittedField[0]]){
	// 			return 1
	// 		}

	// 		if(!a[splittedField[0]] && !b[splittedField[0]]){
	// 			return 0
	// 		}

	// 		new Date(b[splittedField[0]][splittedField[1]]) - new Date(a[splittedField[0]][splittedField[1]])
	// 	})
		
	// 	setFilteredConnections(sortedData)
	// }


	const countersHandler = () => {
		const newCounters = {
			subscriptions: {
				subscribed: 0,
				unsubscribed: 0,
				new: 0,
				trial: 0,
			},
			types: {
				leantech: 0,
				saltedge: 0,
			},
			banks: {
				...banks?.reduce((banks, bank) => {
					banks[bank.name] = 0;
					return banks;
				}, {}),
			},
		};
		
		data.forEach(connection => {
			const { subscription, type, bank } = connection;
			switch (subscription) {
				case 'SUBSCRIBED':
					newCounters.subscriptions.subscribed += 1;
					break;
				case 'UNSUBSCRIBED':
					newCounters.subscriptions.unsubscribed += 1;
					break;
				case 'NEW':
					newCounters.subscriptions.new += 1;
					break;
				case 'TRIAL':
					newCounters.subscriptions.trial += 1;
					break;
				}
					
			switch (type) {
				case 'SALTEDGE':
					newCounters.types.saltedge += 1;
					break;
				case 'LEANTECH':
					newCounters.types.leantech += 1;
					break;
			}
						
			switch (bank) {
				case bank:
					newCounters.banks[bank] += 1;
					break;
			}
		});
								
		setCounters(newCounters);
	};
							
							const getError = (id) => {
								api.history.getConnectionHistory(id).then((connections) => {
			const failed = connections.filter((connection) => connection.status !== "SUCCESS");
			const lastFailed = failed[failed.length - 1];
			
			setPopup({
				show: true,
				error: lastFailed.status,
				information_action_message: lastFailed.information_action_message,
				information_mobile_message: lastFailed.information_mobile_message,
				information_service_message: lastFailed.information_service_message,
			});
		}).catch((err) => {
			console.log(err);
		})
	}

		
	const clearFilters = () => {
		setSearch("");
		setBankSelected([]);
		setSubscribeSelected([]);
		setFilter({
			status: null,
			search: null,
			limit: connections.length,
			bank: [],
			subscribe: [],
			accountCount: null,
			transactionCount: null,
			connection: null,
			lastTransaction: null,
			lastAutomaticSuccess: null,
			lastManualSuccess: null,
			lastAutomaticFail: null,
			lastManualFail: null,
			created: null,
			lastSync: null,
			type: null,
			withPlus: false,
			changed: false,
		});
		
	};

return (
		<div>
			{
				popup.show && <Popup title="Connection Error" setClosure={()=>{setPopup({...popup,show:false})}}>
					<h4>Error: <span className={styles.popupText}>{popup.error}</span></h4>
					<h4>Action Message: <span className={styles.popupText}>{
																		popup.information_action_message
																			? popup.information_action_message?.length !== 0 
																				? popup.information_action_message 
																				: "None" 
																			: "None"
																		}
																	</span></h4>
					<h4>Mobile Message: <span className={styles.popupText}>{
																		popup.information_mobile_message
																			? popup.information_mobile_message?.length !== 0
																				? popup.information_mobile_message
																				: "None"
																			: "None"
																		}
																	</span></h4>
					<h4>Service Message: <span className={styles.popupText}>{
																		popup.information_service_message
																			? popup.information_service_message?.length !== 0
																				? popup.information_service_message
																				: "None"
																			: "None"
																		}
																	</span></h4>
				</Popup>
			}
			<Header />
			<section>
				<Aside />
				<main className="content">
					{(loading) && <LinearProgress color="secondary" />}
					<div className={styles.wrapper}>
						<h1 className={styles.basicTitle}>Connections</h1>
						<div className={styles.table}>
							<header className={styles.header}>
								<div className={styles.header_left}>
								<h5 className={styles.title}>Connection Dashboard</h5>
									<div className={styles.header_left_wrapper}>
										<div className={styles.dayWrapper}>
											<ul>
												<li>
													<h5 className={styles.small_title}>Total Numbers of Users with Bank Connections </h5>
													<h4>{dashboard.userConnectionCountAllUniq}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections</h5>
													<h4 >{dashboard.userConnectionCountAll}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Average Bank Connections per user with a bank connection</h5>
													<h4>{roundPercentage(dashboard.userConnectionCountAll / dashboard.userConnectionCountAllUniq) / 100}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Lean Connections</h5>
													<h4>{dashboard.userConnectionWithLeantech}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Lean Connections</h5>
													<h4>{roundPercentage(dashboard.userConnectionWithLeantech / dashboard.userConnectionCountAll) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Saltedge Connections</h5>
													<h4>{dashboard.userConnectionWithSaltedge}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Saltedge Connections</h5>
													<h4>{roundPercentage(dashboard.userConnectionWithSaltedge / dashboard.userConnectionCountAll) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Users with Lean Connections only</h5>
													<h4>{(dashboard.userConnectionWithLeantechUniq)}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Users with Saltedge Connections only</h5>
													<h4>{dashboard.userConnectionWithSaltedgeUniq}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Users with both Lean & Saltedge Connections</h5>
													<h4 >{+dashboard.userConnectionWithBoth}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Percentage of Subscribed Users with at least 1 Bank Connection</h5>
													<h4>{roundPercentage((+dashboard.userSubscriptionWithMonthly + +dashboard.userSubscriptionWithYearly) / (+dashboard.userConnectionWithSaltedgeUniq + +dashboard.userConnectionWithLeantechUniq)) + "%"}</h4>

												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections up to last month</h5>
													<h4 >{dashboard.connectionUpLastMonth}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Increase/decrease in Number of Bank Connections from last month so far</h5>
													<h4>{dashboard.connectionLastMonth}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage Increase/decrease in Number of Bank Connections from last month</h5>
													<h4 >{roundPercentage(dashboard.connectionLastMonth/dashboard.connectionUpLastMonth) + '%'}</h4>
												</li>
											</ul>
										</div>
									</div>
									{/* ///////////////////////////////// */}
									<h5 className={styles.title}>Connection Refreshes Dashboard</h5>
									<div className={styles.header_left_wrapper}>
										<div className={styles.dayWrapper}>
											<ul>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections</h5>
													<h4>{dashboard.userConnectionCountAll}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections from Subscribed Users</h5>
													<h4 >{+dashboard.userSubscriptionConnectionWithYearly + +dashboard.userSubscriptionConnectionWithMonthly}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Bank Connections from Subscribed Users</h5>
													<h4>{roundPercentage((+dashboard.userSubscriptionConnectionWithYearly + +dashboard.userSubscriptionConnectionWithMonthly) / dashboard.userConnectionCountAll) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections from Trial Users</h5>
													<h4>{dashboard.userConnectionWithTrial}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Bank Connections from Trial Users</h5>
													<h4>{roundPercentage(dashboard.userConnectionWithTrial / (dashboard.userConnectionCountAll)) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Bank Connections from Unsubscribed or Basic Users</h5>
													<h4>{dashboard.userConnectionWithUnsubscribed}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank connections</h5>
													<h4>{(+dashboard.userSubscriptionConnectionWithYearly + +dashboard.userSubscriptionConnectionWithMonthly) + +dashboard.userConnectionWithTrial}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank Connections with Autorefresh</h5>
													<h4>{dashboard.connectionsHaveAutorefresh}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Active Bank Connections with Autorefresh</h5>
													<h4>{roundPercentage(dashboard.connectionsHaveAutorefresh / ((+dashboard.userSubscriptionConnectionWithYearly + +dashboard.userSubscriptionConnectionWithMonthly) + +dashboard.userConnectionWithTrial)) + "%"}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Total Number of Active Bank Connections with non-Autorefresh</h5>
													<h4 >{dashboard.connectionsNonAutorefresh}</h4>
												</li>
												<li>
													<h5 className={styles.small_title}>Percentage of Active Bank Connections with non-Autorefresh</h5>
													<h4>{roundPercentage(dashboard.connectionsNonAutorefresh / (+dashboard.userSubscriptionConnectionWithYearly + +dashboard.userSubscriptionConnectionWithMonthly + +dashboard.userConnectionWithTrial)) + "%"}</h4>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</header>
							<TableContainer component={Paper}>
							<Box sx={{ m: 3}} display="flex" justifyContent="center" width={"100%"}>
								<TextField id="search" size="small" className={styles.searchBar}
									label="Search" variant="outlined" value={search} onChange={(e) => setSearch(e.target.value)} />
								<div className={styles.spacing}>
								</div>
								<Button variant="contained" className={styles.spacing} onClick={
									()=>{
										handleFilter({
											...stateFilter,
											search,
										});
									}
								}>Find</Button>
								<div className={styles.spacing}>
								</div>
								<Button variant="contained" className={styles.spacing} color={"primary"} onClick={()=>{
									dispatch(getConnections(1));
								}}>
									Update data
								</Button>
								<div className={styles.spacing}>
								</div>
								<Button variant="contained" className={styles.spacing} color={"primary"} onClick={()=>{
										downloadExcel();
									}}>
										Excel
								</Button>
								<div className={styles.spacing}>
								</div>
								<Button variant="contained" className={styles.spacing} disabled={!stateFilter.changed} onClick={()=>{
									clearFilters();
								}}>
									Clear Filters
								</Button>
								<div className={styles.spacing}>
								</div>
								<Grid item xs={4} className={styles.column}>
									<FormControlLabel control={<Checkbox onChange={
										(e) => {
											console.log(e.target.checked);
										}
									} />} label="only active" />
								</Grid>
								<Grid>
									<FormControlLabel label="with plus" control={<Checkbox checked={stateFilter.withPlus} value={stateFilter.withPlus} onChange={(e)=>handleFilter({
										...stateFilter,
										withPlus: e.target.checked,
									})}/>} />
								</Grid>
								<Grid>
									<p>Page Limit:</p>
									<select
										value={stateFilter.limit}
										onChange={(val)=>handleFilter({
											...stateFilter,
											limit: val.target.value
										})}
									>
										<option value={10}>10</option>
										<option value={25}>25</option>
										<option value={50}>50</option>
										<option value={100}>100</option>
										<option value={connections?.length}>All</option>
									</select>
								</Grid>
							</Box>
							{(loadingConnection) && <LinearProgress color="secondary" />}
								<Table size="small" aria-label="a dense table">
									<TableHead>
										<TableRow>
											<TableCell>№</TableCell>
											<TableCell>Connection Id</TableCell>
											<TableCell>
												<Link component="button" onClick={() => sortData("providerConnectionId")}>
													Provider Connection Id
												</Link></TableCell>
											<TableCell>
												<Link component="button" onClick={() => sortData("user_email")}>
													Email
												</Link></TableCell>
											<TableCell>
												<div className={styles.multiSelectWrapper}>
													<MultiSelect
														options={[
															{
																label: `SUBSCRIBED (${counters.subscriptions.subscribed})`,
																value: "SUBSCRIBED",
															},
															{
																label: `UNSUBSCRIBED (${counters.subscriptions.unsubscribed})`,
																value: "UNSUBSCRIBED",
															},
															{
																label: `NEW (${counters.subscriptions.new})`,
																value: "NEW",
															},
															{
																label: `TRIAL (${counters.subscriptions.trial})`,
																value: "TRIAL",
															}
														]}
														value={subscribeSelected}
														onChange={(val) => setSubscribeSelected(val)}
														labelledBy="Select">
													</MultiSelect>
												</div>
												Subscription
											</TableCell>
											<TableCell>
												<select  onChange={(val) => handleFilter({ ...stateFilter, type: val.target.value })} className="btn btn-sm btn-outline-secondary dropdown-toggle">
													<option value="">...</option>
													<option value="SALTEDGE" >SALTEDGE {`(${counters.types.saltedge})`}</option>
													<option value="LEANTECH">LEANTECH {`(${counters.types.leantech})`}</option>
												</select>
											</TableCell>
											<TableCell>
												<div className={styles.multiSelectWrapper}>
													<MultiSelect
													options={banks? banks.map((b) => {
														return {
															label: `${b.name} (${counters.banks[b.name]})`,
															value: b.name
														}
													}) : []}
													value={bankSelected}
													onChange={(val) => setBankSelected(val)}
													labelledBy="Select"
												/>
												</div>
												<Link component="button" onClick={() => sortData("bank")}>
													Bank
												</Link>
											</TableCell>
											<TableCell><Link component="button" onClick={() => sortData("accountCount")}>Account counts</Link></TableCell>
											<TableCell><Link component="button" onClick={() => sortData("transactionCount")}>Transaction count</Link></TableCell>
											<TableCell><Link component="button" onClick={() => sortData("transactionNewCount")}>Transaction new count</Link></TableCell>
											<TableCell><Link component="button" onClick={() => sortData("lastTransaction")}>Last transaction</Link></TableCell>
											<TableCell><Link component="button" onClick={() => {
												sortDate("manualSyncAutomatic.success_at");
											}}>Last success automatic refresh</Link></TableCell>
											<TableCell><Link component="button" onClick={() => {
												sortDate("manualSyncManual.success_at");
											}} > Last success manual refresh</Link></TableCell>
											<TableCell><Link component="button" onClick={() => {
												sortDate("manualSyncAutomatic.fail_at");
											}}>Last fail automatic refresh</Link></TableCell>
											<TableCell><Link component="button" onClick={() => {
												sortDate("manualSyncManual.fail_at");
											}}>Last fail manual refresh</Link></TableCell>
											<TableCell><Link component="button" onClick={() => {
												sortData("created_at")
											}}>Created Connection</Link></TableCell>
											<TableCell>
												<select onChange={(val) => handleFilter({ ...stateFilter, status: val.target.value })} className="btn btn-sm btn-outline-secondary dropdown-toggle">
													<option value="">...</option>
													<option value="Success" >Success</option>
													<option value="Failed">Failed</option>
												</select>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											rows.length !==0 ? rows?.map((connection, index) => {

												
													return (
													<TableRow key={connection.id}>
														<TableCell align="left">{index + 1}</TableCell>
														<TableCell align="left">{connection.id}</TableCell>
														<TableCell align="left">
															<Link component="button" onClick={()=>{ 
																const searchParams = new URLSearchParams();
																searchParams.set("connectionId", connection.id);
																searchParams.set("connectionProviderId", connection.providerConnectionId);
																searchParams.set("email", connection.user_email);
																searchParams.set("bank", connection.bank);
																searchParams.set("identifier", connection.identifier);

																history.push({
																	pathname:"/history",
																	search: `?${searchParams.toString()}`
																})
															}}>
																{connection.providerConnectionId}
															</Link>
														</TableCell>
														<TableCell align="left">{connection.user_email}</TableCell>
														<TableCell align="left">{connection.subscription}</TableCell>
														<TableCell align="left">{connection.type}</TableCell>
														<TableCell align="left">{connection.bank}</TableCell>
														<TableCell align="left">{connection.accountCount}</TableCell>
														<TableCell align="left">{connection.transactionCount}</TableCell>
														<TableCell align="left">{connection.transactionNewCount}</TableCell>
														<TableCell align="left">{connection.lastTransaction ? moment(connection.lastTransaction).format('MM/DD/YYYY HH:mm') : "_"}</TableCell>
														<TableCell align="left">{connection.manualSyncAutomatic?.success_at ? moment(connection.manualSyncAutomatic?.success_at).format('MM/DD/YYYY HH:mm') : "_"}</TableCell>
														<TableCell align="left">{connection.manualSyncManual?.success_at ? moment(connection.manualSyncManual?.success_at).format('MM/DD/YYYY HH:mm') : "_"}</TableCell>
														<TableCell align="left">{connection.manualSyncAutomatic?.fail_at ? moment(connection.manualSyncAutomatic?.fail_at).format('MM/DD/YYYY HH:mm') : "_"}</TableCell>
														<TableCell align="left">{connection.manualSyncManual?.fail_at ? moment(connection.manualSyncManual?.fail_at).format('MM/DD/YYYY HH:mm') : "_"}</TableCell>
														<TableCell align="left">{moment(connection.created_at).format('MM/DD/YYYY HH:mm')}</TableCell>
														{
															connection.status === "Failed" 
																? <TableCell align="left">
																	<Link className={styles.clickable} onClick={()=>getError(connection.id)}>
																		{connection.status}
																	</Link>
																</TableCell>
																: <TableCell align="left">{connection.status}</TableCell>
														}
													</TableRow>
													)})
													: <TableRow>
														<TableCell height={280} colSpan={14} align="center">No data</TableCell>
													</TableRow>
											}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
						<div className={styles.paginationWrapper}>
							<PaginationComponent pageCount={pagination.pageCount} paginationHandler={paginationHandler} />
						</div>
					</div>
				</main >
			</section >
		</div >
	);
}

export default Home;
