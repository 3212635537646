import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { PATH } from '../../utills/Path';
import styles from './style.module.scss';
import PropTypes from 'prop-types';
import { getUserTokenFromStorage } from '../../utills/user';

function Aside({ location }) {
	const { pathname } = location;
	const user = getUserTokenFromStorage();

	return (
		<aside className={styles.wrapper}>
			<nav>
				<ul>
					<li>
						<Link to={PATH.users} className={pathname === PATH.users ? styles.active : ''}>
							Users
						</Link>
					</li>
					<li>
						<Link to={PATH.home} className={pathname === PATH.home ? styles.active : ''}>
							Connections
						</Link>
					</li>
					<li>
						<Link to={PATH.statistics} className={pathname === PATH.statistics ? styles.active : ''}>
							Statistics
						</Link>
					</li>
					<li>
						<Link to={PATH.admins} className={pathname === PATH.admins ? styles.active : ''}>
							Admins
						</Link>
					</li>
					<li>{ user.role === 'SUPER_ADMIN' &&
						<Link to={PATH.settings} className={pathname === PATH.settings ? styles.active : ''}>
							Settings
						</Link> }
					</li>
					<li>
						<Link to={PATH.categories} className={pathname === PATH.categories ? styles.active : ''}>
							Categories
						</Link>
					</li>
					<li>
						{ user.role === 'SUPER_ADMIN' &&
							<Link to={PATH.banks} className={pathname === PATH.banks ? styles.active : ''}>
								Banks
							</Link>
						}
					</li>
					<li>
						<Link to={PATH.banksStatistics} className={pathname === PATH.banksStatistics ? styles.active : ''}>
							Banks Statistics
						</Link>
					</li>
				</ul>
			</nav>
		</aside>
	);
}

Aside.propTypes = {
	location: PropTypes.object.isRequired
};

export default withRouter(Aside);
