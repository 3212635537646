import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import { deleteAdmin, getAdmins, editAdmin as editAdminAction, approveAdmin } from '../../redux/admins/actions';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import styles from './style.module.scss';
import Window from '../../components/Modal';
import EditAdminForm from '../../forms/editUser';
import DeleteAdminModal from '../../components/DeleteAdminModal';
import PaginationComponent from '../../components/Pagination/indedx';
import { withRouter } from 'react-router-dom';
import { ROLE } from '../../utills/user';

function Admins({history}) {
	const dispatch = useDispatch();
	const { loading, admins, pageCount } = useSelector(state => state.admins);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [editAdmin, setEditAdmin] = useState(null);

	const handleCloseModal = () => {
		setIsOpenModal(false);
		setIsOpenDeleteModal(false);
		setEditAdmin(null);
	};

	const getPage = () => history.location.search.split('page=')[1];

	useEffect(
		() => {
			const page = history.location.search.split('page=')[1];
			if(!page){
				dispatch(getAdmins());
			}
		},
		[dispatch]
	);

	const deleteAdminHandler = id => {
		dispatch(deleteAdmin(id, () => dispatch(getAdmins(getPage()))));
	};

	const editAdminModalHandler = admin => {
		setEditAdmin(admin);
		setIsOpenModal(true);
	};

	const deleteAdminModalHandler = admin => {
		setEditAdmin(admin);
		setIsOpenDeleteModal(true);
	};

	const editAdminSubmitHandler = (id, data) => {
		dispatch(
			editAdminAction(id, data, () => {
				setIsOpenModal(false);
				dispatch(getAdmins(getPage()));
			})
		);
	};

	const approveAdminHandler = id => {
		dispatch(
			approveAdmin(
				id,
				{
					is_approved: true
				},
				() => dispatch(getAdmins(getPage()))
			)
		);
	};

	const rejectAdminHandler = id => {
		dispatch(
			approveAdmin(
				id,
				{
					is_approved: false
				},
				() => dispatch(getAdmins(getPage()))
			)
		);
	};

	const paginationHandler = (page) => {
		dispatch(getAdmins(page));
	}

	const currentUserId = useMemo(() => {
		const access_token = localStorage.getItem('access_token');
		if (access_token) {
			const payload = JSON.parse(atob(access_token.split('.')[1]));

			return payload.sub
		}
		return '';
	}, []);

	return (
		<div>
			<Header />
			<section>
				<Aside />
				<main className="content">
					{loading && <LinearProgress color="secondary" />}
					<Window handleClose={handleCloseModal} open={isOpenModal}>
						<EditAdminForm admin={editAdmin} editAdminSubmitHandler={editAdminSubmitHandler} handleClose={handleCloseModal}/>
					</Window>
					<Window handleClose={handleCloseModal} open={isOpenDeleteModal} isBig>
						<DeleteAdminModal admin={editAdmin} deleteAdminHandler={deleteAdminHandler} handleClose={handleCloseModal}/>
					</Window>
					<div className={styles.tableWrapper}>
						<h1 className={styles.basicTitle}>Admins</h1>
						<TableContainer component={Paper}>
							<Table size="small" aria-label="a dense table">
								<TableHead>
									<TableRow>
										<TableCell>Email address</TableCell>
										<TableCell align="left">Date created</TableCell>
										<TableCell align="left">Last Login</TableCell>
										<TableCell align="left">Permissions</TableCell>
										<TableCell align="left">Approved</TableCell>
										<TableCell align="left">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{admins.map((admin) => (
										<TableRow key={admin.id}>
											<TableCell component="th" scope="row">
												{admin.email}
											</TableCell>
											<TableCell align="left">{moment(admin.createdAt).format('MM/DD/YYYY HH:mm')}</TableCell>
											<TableCell align="left">
												{admin.login_at ? moment(admin.login_at).format('MM/DD/YYYY HH:mm') : ' '}
											</TableCell>
											<TableCell align="left">{admin.user_role === ROLE.admin ? 'Admin' : 'Super Admin'}</TableCell>
											<TableCell align="left">
												{admin.approved_by ? (
													<div className={styles.approvedByWrapper}>By {admin.approved_by.email}</div>
												) : (
													''
												)}
												{!admin.is_approved && (
													<div className={styles.approveButtonWrapper}>
														<Button
															size="small"
															variant="outlined"
															color="primary"
															onClick={() => approveAdminHandler(admin.id)}
														>
															Approve
														</Button>
														{' '}
														<Button
															size="small"
															variant="outlined"
															color="secondary"
															onClick={() => rejectAdminHandler(admin.id)}
														>
															Reject
														</Button>
													</div>
												)}
											</TableCell>
											<TableCell align="left">
												{currentUserId !== admin.id && (
													<div className={styles.editDeleteButtonWrapper}>
														<Button
															size="small"
															variant="contained"
															color="primary"
															onClick={() => editAdminModalHandler(admin)}
														>
															Edit
														</Button>{' '}
														<Button
															size="small"
															variant="contained"
															color="secondary"
															onClick={() => deleteAdminModalHandler(admin)}
														>
															Delete
														</Button>
													</div>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<div className={styles.paginationWrapper}>
							<PaginationComponent pageCount={pageCount} paginationHandler={paginationHandler} />
						</div>
					</div>
				</main>
			</section>
		</div>
	);
}

Admins.propTypes = {
	history: PropTypes.object.isRequired
};


export default withRouter(Admins);


