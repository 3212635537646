import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {Link} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import api from '../../utills/api';
import { PATH } from '../../utills/Path';
import styles from './style.module.scss';
import {toast} from "react-toastify";

function ResetPasswordForm({ history }) {
	const {
		register,
		getValues,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const [step, setStep] = useState(1);
	const [responseError, setResponseError] = useState(null);

	const onSubmit = (data) => {
		const values = getValues();
		console.log(values)
		console.log(data);
		if (step === 1) {
			api.user.resetPassword({email: data.emailRequired})
				.then(res => {
					console.log(res.status)
					if (res.status === 200) {
						setStep(2);
					} else if (res.status === 422) {
						setResponseError('Email not found')
					}
				})
				.catch((err) => {
					console.log(err)
				});
		}
		if (step === 2) {
			setStep(3);
		}
		if (step === 3) {
			api.user.resetPassword({
				email: data.emailRequired,
				code: data.codeRequired,
				new_password: data.passwordRequired
			})
				.then(res => {
					if (res.status === 200) {
						history.push(PATH.signin);
						toast.success('Success');
					}
				})
				.catch((err) => {
					console.log(err)
				});
		}
	};

	return (
		<div className={styles.wrapper}>

			<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
				{step === 1 && (
					<>
						<h1 className={styles.title}>Reset Password</h1>
						<div className={styles.row}>
							<input
								placeholder="Enter your mail"
								className={styles.input}
								{...register('emailRequired', {
									required: 'Email is required',
									pattern: {
										value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
										message: 'Please enter a valid email',
									},
								})}
							/>
							{(errors.emailRequired) && (
								<span className={styles.error}>{errors.emailRequired.message}</span>
							)}
						</div>
						<div className={styles.submitWrapper}>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								className={styles.submit}
								disabled={!!errors.emailRequired}
							>
								Continue
							</Button>
						</div>
					</>
				)}

				{step === 2 && (
					<>
						<h1 className={styles.title}>Verify Password Reset</h1>
						<div className={styles.row}>
							<input
								placeholder="Enter your CODE"
								className={styles.input}
								{...register('codeRequired', {
									required: 'Code is required',
									maxLength : {
										value: 4,
										message: '4-digit code'
									},
									minLength: {
										value: 4,
										message: '4-digit code'
									},
									pattern: {
										value: /^[0-9]*$/g,
										message: '4-digit code',
									}
								})}
							/>
							{(errors.codeRequired) && (
								<span className={styles.error}>{errors.codeRequired.message}</span>
							)}
						</div>
						<div className={styles.submitWrapper}>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								className={styles.submit}
								disabled={!!errors.codeRequired}
							>
								Confirm
							</Button>
						</div>
					</>
				)}

				{step === 3 && (
					<>
						<h1 className={styles.title}>Set New Password</h1>
						<div className={styles.row}>
							<input
								placeholder="Create your password"
								className={styles.input}
								type="password"
								{...register('passwordRequired', {
									required: true,
									minLength: {
										value: 8,
										message: '8+ chars'
									},
									pattern: {
										value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
										message: 'mixed-case, digits and special characters',
									},
								})}
							/>
							{(errors.passwordRequired) && (
								<span className={styles.error}>{errors.passwordRequired.message}</span>
							)}
						</div>
						<div className={styles.row}>
							<input
								placeholder="Re-type new password"
								className={styles.input}
								type="password"
								{...register('passwordRepeat', {
									validate: {
										isEqual: v => {
											const values = getValues();
											return v === values.passwordRequired;
										}
									}
								})}
							/>
							{(errors.passwordRepeat?.type === 'isEqual') && (
								<span className={styles.error}>Passwords mismatch</span>
							)}
						</div>
						<div className={styles.submitWrapper}>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								className={styles.submit}
								disabled={!!errors.passwordRequired}
							>
								Confirm
							</Button>
						</div>
					</>
				)}
				{!!responseError && <div className={styles.error}>{responseError}</div>}
				<footer className={styles.footer}>
					<Link to={PATH.signup}>Sign up</Link> <span className={styles.divider}> | </span>
					<Link to={PATH.signin}>Sign in</Link>
				</footer>
			</form>
		</div>
	);
}

ResetPasswordForm.propTypes = {
	history: PropTypes.object.isRequired
};

export default withRouter(ResetPasswordForm);
