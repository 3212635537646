import React, { useEffect, useState } from 'react';
import {
	Box, Button, Collapse, FormControl, Grid, IconButton, TextField,
} from '@material-ui/core';

import Aside from '../../components/Aside';
import Header from '../../components/Header';
import styles from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings, putSettings } from '../../redux/settings/actions';
import { Alert } from '@material-ui/lab';

function Settings() {
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const [inputValues, setInputValues] = useState({
		TRIAL_PERIOD: null
	})

	useEffect(
		() => {
			dispatch(getSettings());
		},
		[dispatch]
	);

	let {
		settings
	} = useSelector(state => state.settings);

	// useEffect(() => {
	// 	settings.forEach(element => {
	// 		inputValues[element.key] = element.value;
	// 	});

	// 	setInputValues(inputValues);
	// }, [settings]);


	// useEffect(() => {
	// 	settings.map(setting => setInputValues({
	// 		...inputValues,
	// 		[setting.key]: setting.value
	// 	}))
	// })

	if (!settings) {
		settings = [];
	}

	const handleSubmit = () => dispatch(putSettings(inputValues));

	const handleChange = ({
		target: {
			name,
			value
		}
	}) => {
		setInputValues({
			...inputValues,
			[name]: value
		})
	}

	console.log(inputValues);

	return (
		<Box>
			<Header />
			<section>
				<Aside />
				<main className="content">
					<div className="wrapper">
						<h1 className={styles.title}>Settings</h1>
						<Collapse in={open}>
							<Alert
								action={
									<IconButton
										aria-label="close"
										color="inherit"
										size="small"
										onClick={() => {
											setOpen(false);
										}}
									>
										x
									</IconButton>
								}
								sx={{ mb: 2 }}
							>
								Updated!
							</Alert>
						</Collapse>
						<Box m={2} pt={3} display="flex" sx={{ p: 2, border: '1px dashed grey' }}>
							<Grid container spacing={2}>
							{settings.map((setting) => (
								<Grid pt={10} key={setting.id} sm="4">
									<FormControl variant="standard">
										<label>{setting.value}</label>
										<TextField onChange={handleChange} id={setting.key} name={setting.key} label={setting.key.slice(0,16) + '...'} helperText={setting.hint} variant="outlined" />
									</FormControl>
								</Grid>
							))}
							</Grid>
						</Box>
						<Box display="flex"
							justifyContent="center"
							sx={
								{
									margin: '10px',
									p: 2
								}
							} > <Button onClick={
								() => {
									handleSubmit();
									setOpen(true);
								}
							}
								variant="outlined" > Update </Button></Box >
					</div>
				</main>
			</section>
		</Box>
	);
}

export default Settings;
