import React from 'react';
import { Button, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ROLE } from '../../utills/user';
import styles from './style.module.scss';

export default function DeleteAdminModal({ admin, deleteAdminHandler, handleClose }) {
	return (
		<div>
			<TableContainer component={Paper}>
				<Table size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell>Email address</TableCell>
							<TableCell align="left">Date created</TableCell>
							<TableCell align="left">Last Login</TableCell>
							<TableCell align="left">Permissions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
							<TableRow>
								<TableCell component="th" scope="row">
									{admin.email}
								</TableCell>
								<TableCell align="left">{moment(admin.createdAt).format('MM/DD/YYYY HH:mm')}</TableCell>
								<TableCell align="left">
									{admin.login_at ? moment(admin.login_at).format('MM/DD/YYYY HH:mm') : ' '}
								</TableCell>
								<TableCell align="left">{admin.user_role === ROLE.admin ? 'Admin' : 'Super Admin'}</TableCell>
							</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<div className={styles.buttonsWrapper}>
				<Button variant="contained" color="secondary" onClick={handleClose}>
					Cancel
				</Button>
				{' '}
				<Button variant="contained" color="primary" onClick={() => deleteAdminHandler(admin.id)}>
					Delete
				</Button>
			</div>
		</div>
	);
}

DeleteAdminModal.propTypes = {
	admin: PropTypes.object,
	deleteAdminHandler: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
};
