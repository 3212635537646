import { Box, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Aside from '../../components/Aside';
import Header from '../../components/Header';
import styles from './style.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBankStatistics } from '../../redux/banksStatistics/actions';

function BanksStatistics() {
	const history = useHistory();
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.banksStatistics.loading);
	const banksStatistics = useSelector((state) => state.banksStatistics.statistics);
	const [rows, setRows] = useState([]);
	
	const [filter, setFilter] = useState({
		auto_refresh: null,
	});
	
	const [sortCriteria, setSortCriteria] = useState({
		sortBy: '',
		sortOrder: '',
	});

	useEffect(() => {
		dispatch(fetchBankStatistics());
	}, [])

	useEffect(() => {
		console.log(banksStatistics);
		setRows(banksStatistics);
	}, [banksStatistics])

	const handleSort = (column) => {
		if (sortCriteria.sortBy === column) {
			setSortCriteria({
				...sortCriteria,
				sortOrder: sortCriteria.sortOrder === 'asc' ? 'desc' : 'asc',
			});
		} else {
			setSortCriteria({
				sortBy: column,
				sortOrder: 'desc',
			});
		}
		
		setRows([...rows].sort((a, b) => {
			if(column !== "name"){
				try {
					a = JSON.parse(a[column]);
					b = JSON.parse(b[column]);
				} catch (error) {
					console.log(error.message);
				}
			} else {
				a = a[column];
				b = b[column];
			}
			if (a > b) {
				return sortCriteria.sortOrder === 'asc' ? 1 : -1;
			}
			if (a < b) {
				return sortCriteria.sortOrder === 'asc' ? -1 : 1;
			}
			return 0;
		}));
	}

	return (
		<div>
			<Header />
			<section>
				<Aside />
				<main className="content">
					{loading && <LinearProgress color="secondary" />}
					<h1 className={styles.basicTitle}>Bank Statistics</h1>
					<div className={styles.wrapper}>
						<Box display="flex">
							<div className={styles.column}>
								<TableContainer component={Paper}>
									<Table>
										<TableHead>
											<TableRow>	
												<TableCell align="left">
													<Link onClick={()=>handleSort("name")}  className={styles.clickable}>
														Bank
													</Link>
												</TableCell>
												<TableCell align="left">Logo</TableCell>
												<TableCell align="center">
													<p>Auto Refresh</p>
													<select onChange={(value)=>{
														let selected = null;
														if (value.target.value === "true") {
															selected = true;
														}

														if (value.target.value === "false") {
															selected = false;
														}

														setFilter({
															...filter,
															auto_refresh: selected,
														});
													}}>
														<option value={null}>...</option>
														<option value={true}>Yes</option>
														<option value={false}>No</option>
													</select>
												</TableCell>
												<TableCell align="center">
													<Link onClick={()=>handleSort("totalNumberConnection")} className={styles.clickable}>
														Total number of connections
													</Link>
												</TableCell>
												<TableCell align="center" className={styles.clickable}>
													<Link onClick={()=>{
														handleSort("totalNumberActiveConnection");
													}}>
														Total number of active connections
													</Link>
												</TableCell>
												<TableCell align="center" className={styles.clickable}>
													<Link onClick={()=>handleSort("totalFailedAttemptConnection")}>
														Failed attempts to sync
													</Link>
												</TableCell>
												<TableCell align="center" className={styles.clickable}>
													<Link onClick={()=>handleSort("totalSuccessAttemptConnection")}>
														Success attempts to refresh
													</Link>	
												</TableCell>
												<TableCell align="center" className={styles.clickable}>
													<Link onClick={()=>handleSort("totalFailedAttemptRefreshConnection")}>
														Failed attempts to refresh
													</Link>
												</TableCell>
												<TableCell align="center">History</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{rows.length !== 0 ? rows.map((bank) => {
												let {
													id,
													name,
													logo,
													autoRefresh,
													totalNumberConnection,
													totalNumberActiveConnection,
													totalFailedAttemptConnection,
													totalSuccessAttemptConnection,
													totalFailedAttemptRefreshConnection,
												} = bank;
												
												totalNumberConnection = parseInt(totalNumberConnection); 
												totalNumberActiveConnection = parseInt(totalNumberActiveConnection);
												totalFailedAttemptConnection = parseInt(totalFailedAttemptConnection);
												totalSuccessAttemptConnection = parseInt(totalSuccessAttemptConnection);
												totalFailedAttemptRefreshConnection = parseInt(totalFailedAttemptRefreshConnection);
												

												if (filter.auto_refresh!==null && filter.auto_refresh !== autoRefresh) return;

												return (
													<TableRow key={id}>
														<TableCell align="left">{name}</TableCell>
														<TableCell align="left">
															<img src={logo} alt={name} height={64} loading={"lazy"}/>
														</TableCell>
														<TableCell align="center">{autoRefresh ? "YES" : "NO"}</TableCell>
														<TableCell align="center">{totalNumberConnection}</TableCell>
														<TableCell align="center" >
															<p>
																{`${totalNumberActiveConnection}`} 
															</p>
															
														</TableCell>
														<TableCell align="center">
															<p>
																{`${totalFailedAttemptConnection}`}
															</p>
															
														</TableCell>
														<TableCell align="center">
															<p>
																{`${totalSuccessAttemptConnection}`}
															</p>
															
														</TableCell>
														<TableCell align="center">{totalFailedAttemptRefreshConnection}</TableCell>
														<TableCell align="center">
															<Link onClick={() => {
																history.push({
																	pathname:"/history",
																	search: `?bank=${name}&bankId=${id}&page=1`,
																});
															}}
															className={styles.clickable}
															>Click Here</Link>
														</TableCell>
													</TableRow>
												)
											}) : loading ? (
												<TableRow>
													<TableCell colSpan={9} align="center">Waiting for data ...</TableCell>
												</TableRow>
											): (
												<TableRow>
													<TableCell colSpan={9} align="center">No data found</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						</Box>
					</div>
				</main>
			</section>
		</div>
	);
}

export default BanksStatistics