import {
    GET_CONNECTION_HISTORY,
    GET_CONNECTION_HISTORY_SUCCESS,
    GET_CONNECTION_HISTORY_ERROR,
    GET_BANK_HISTORY,
    GET_BANK_HISTORY_SUCCESS,
    GET_BANK_HISTORY_ERROR
} from './constants';
import api from '../../utills/api';

export const getConnectionHistory = () => ({
    type: GET_CONNECTION_HISTORY
});

export const getConnectionHistorySuccess = (payload) => ({
    type: GET_CONNECTION_HISTORY_SUCCESS,
    payload
});

export const getConnectionHistoryError = (payload) => ({
    type: GET_CONNECTION_HISTORY_ERROR,
    payload
});

export const getBankHistory = () => ({
    type: GET_BANK_HISTORY
});

export const getBankHistorySuccess = (payload) => ({
    type: GET_BANK_HISTORY_SUCCESS,
    payload
});

export const getBankHistoryError = (payload) => ({
    type: GET_BANK_HISTORY_ERROR,
    payload
});

export const fetchConnectionHistory = (id) => (dispatch) => {
    dispatch(getConnectionHistory());

    api.history.getConnectionHistory(id)
        .then((data)=>{
            if (!data.error) {
                data = data.sort((a,b)=>new Date(b.start_at) - new Date(a.start_at));
                dispatch(getConnectionHistorySuccess(data))
            } else {
                dispatch(getConnectionHistoryError({error: data}))
            } 
        })
        .catch((err)=>{
            dispatch(getConnectionHistoryError(err))
        })
}

export const fetchBankHistory = (id) => (dispatch) => {
    dispatch(getBankHistory());

    api.history.getBankHistory(id)
        .then((data)=>{
            console.log(data);
            if (!data.error) {
                dispatch(getBankHistorySuccess(data))
            } else {
                dispatch(getBankHistoryError({error: data}))
            } 
        })
        .catch((err)=>{
            dispatch(getBankHistoryError(err))
        })
}