/*eslint-disable*/ 
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
// import { PATH } from '../../utills/Path';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { clearUserError, getNewCode, verifyCode } from '../../redux/user/actions';
import CodeVerificationForm from '../../forms/codeVerification';
import { PATH } from '../../utills/Path';


function CodeVerification({ history }) {
	const { loading, error, user } = useSelector((state) => state.user);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearUserError());
		return () => dispatch(clearUserError());
	}, [dispatch]);

	const getNewCodeHandler = (e) => {
		e.preventDefault();
		dispatch(getNewCode());
	};

	const codeVerificationHandler = (data) => {
		dispatch(
			verifyCode(data, () => {
					history.push(PATH.signin);
			})
		);
	};
	return (
		<CodeVerificationForm
			getNewCodeHandler={getNewCodeHandler}
			error={error}
			isLoading={loading}
			codeVerificationHandler={codeVerificationHandler}
		/>
	);
}

CodeVerification.propTypes = {
	history: PropTypes.object.isRequired
};

export default withRouter(CodeVerification);
