export const PATH = {
	signin: '/login',
	home: '/connections',
	resetPassword: '/reset-password',
	signup: '/register',
	admins: '/admins',
	users: '/',
	statistics: '/statistics',
	settings: '/settings',
	categories: '/categories',
	banks: '/banks',
	banksStatistics: '/banks-statistics',
	history: '/history',
	usercategories: '/user-categories',
	codeVerification: '/code-verification',
	all: '*'
};