import {
	GET_ALL_SETTING_LIST,
	GET_ALL_SETTING_SUCCESS,
	GET_ALL_SETTING_ERROR,
} from './constants';

const initState = {
	isLogin: false,
	loading: false,
	error: null,
	settings: null
};

export default (state = initState, action) => {
	const {
		type,
		payload
	} = action;
	switch (type) {
		case GET_ALL_SETTING_LIST:
			return {
				...state,
				loading: true
			};
		case GET_ALL_SETTING_ERROR:
			return {
				...state,
				loading: false,
					error: payload
			};
		case GET_ALL_SETTING_SUCCESS:
			return {
				...state,
				loading: false,
					settings: payload
			};
		default:
			return {
				...state
			};
	}
}