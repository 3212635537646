import {
	GET_USER_CATEGORIES_REQUEST,
	GET_USER_CATEGORIES_SUCCESS,
	GET_USER_CATEGORIES_ERROR,
GET_SUB_USER_CATEGORIES_REQUEST,
GET_SUB_USER_CATEGORIES_SUCCESS,
GET_SUB_USER_CATEGORIES_ERROR,
} from './constants';
import {CATEGORIES_TYPE} from "../../utills/categories";

const initState = {
	loading: false,
	user_categories: {
		[CATEGORIES_TYPE.EXPENSE]: [],
		[CATEGORIES_TYPE.INCOME]: [],
	},
	subCategory: {}
};

export default (state = initState, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_USER_CATEGORIES_REQUEST:
		case GET_SUB_USER_CATEGORIES_REQUEST:
			return {
				...state,
				loading: true
			};
		case GET_USER_CATEGORIES_SUCCESS:
			return {
				...state,
				loading: false,
				user_categories: {...state.user_categories, [action.categoryType]: payload.data}
			};
		case GET_SUB_USER_CATEGORIES_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case GET_USER_CATEGORIES_ERROR:
		case GET_SUB_USER_CATEGORIES_ERROR:
			return {
				...state,
				loading: false
			};
		default:
			return {
				...state
			};
	}
};
