import {
    GET_CONNECTION_HISTORY,
    GET_CONNECTION_HISTORY_SUCCESS,
    GET_CONNECTION_HISTORY_ERROR,
    GET_BANK_HISTORY,
    GET_BANK_HISTORY_SUCCESS,
    GET_BANK_HISTORY_ERROR
} from './constants';

const initstate = {
    loading: false,
    error: null,
    connectionHistory: [],
    bankHistory: []
}

export default (state = initstate, action) => {
    const {type, payload} = action;
    
    switch (type) {
        case GET_CONNECTION_HISTORY:
            return {
                ...state,
                loading: true
            }

        case GET_CONNECTION_HISTORY_SUCCESS:
            return{
                ...state,
                loading: false,
                connectionHistory: payload
            }
        
        case GET_CONNECTION_HISTORY_ERROR:
            return {
                connectionHistory: [],
                loading: false,
                error: payload
            }

        case GET_BANK_HISTORY:
            return {
                ...state,
                loading: true
            }

        case GET_BANK_HISTORY_SUCCESS:
            return{
                ...state,
                loading: false,
                bankHistory: payload
            }
        
        case GET_BANK_HISTORY_ERROR:
            return {
                bankHistory: [],
                loading: false,
                error: payload
            }
    
        default:
            return {
                ...state
            }
    }
}