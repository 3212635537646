/*eslint-disable*/

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../../utills/auth';
import { PATH } from '../../utills/Path';

const PublicRoute = ({ component: Component, ...rest }) =>
	<Route
		{...rest}
		render={props => {
			const { pathname } = props.location;
			const to = {
				pathname: '/',
				state: {
					from: props.location
				}
			};
			if (auth.isAuthenticated() && (pathname === PATH.signin || pathname === PATH.signup || pathname === PATH.resetPassword)) {
				return <Redirect to={to} />;
			} else return <Component {...props} />;
		}}
	/>;

export default PublicRoute;
