import api from '../../utills/api';
import {
	GET_ALL_SETTING_LIST,
	GET_ALL_SETTING_SUCCESS,
	GET_ALL_SETTING_ERROR,
} from './constants';

export const getAllSettingList = () => ({
	type: GET_ALL_SETTING_LIST
});

const getAllSettingSuccess = (payload) => ({
	type: GET_ALL_SETTING_SUCCESS,
	payload
});

const getAllSettingError = (payload) => ({
	type: GET_ALL_SETTING_ERROR,
	payload
});

// const getAllSettingUpdateRequest = (payload) => ({
// 	type: GET_ALL_SETTING_ERROR,
// 	payload
// });

export const getSettings = () => (dispatch) => {
	dispatch(getAllSettingList());

	api.settings
		.getSettings()
		.then((data) => {
			if (!data.error) {
				console.log(data);
				dispatch(getAllSettingSuccess(data));
			} else {
				dispatch(getAllSettingError(data));
			}
		})
		.catch((err) => {
			dispatch(getAllSettingError(err))
		});
};

export const putSettings = (body) => (dispatch) => {
	dispatch(getAllSettingList());
	console.log("test",body);
	api.settings
		.putSettings(body)
		.then((data) => {
			if (!data.error) {
				console.log(data);
				dispatch(getAllSettingSuccess(data));
			} else {
				//dispatch(getAllSettingError(data));
			}
		})
		.catch((err) => {
			console.log(err);
			//dispatch(getAllSettingError(err))
		});
};